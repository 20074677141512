import {reasonReportData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const reportReasonListAction = () => async (dispatch) =>{
    try{
      const  reasonReportList  = await commonService.withLogin(`/report-reason-list`)

      if(reasonReportList){
        dispatch({type: reasonReportData.GET_REASONREPORT_SUCCESS , payload: {datasss: reasonReportList.data}})
      }
      else{
        dispatch({type: reasonReportData.GET_REASONREPORT_FAILURE , payload: {data: reasonReportList.data}})
      }
  
  }catch(error){
    
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: reasonReportData.GET_REASONREPORT_FAILURE , payload: error.message})
  }
}

export default reportReasonListAction;