import axios from "axios";

export const commonService = {
  withToken,
  withLogin,
  withOutToken,
  withOutLogin,
};

// const BASE_URL = "https://staging-admin-api.ozgigs.com.au/api";
// const BASE_URL = "https://staging-api.ozgigs.com.au/api";
const BASE_URL = process.env.REACT_APP_BASE_URL;

//-- It's common function for using the token
function withToken(apiName, data) {
  let tokenObj = JSON.parse(localStorage.getItem("token"));
  return axios({
    method: "POST",
    url: `${BASE_URL + apiName}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.api_token}`,
    },
    data: data,
  }).then((handleResponse) => {
    return handleResponse;
  });
}

//-- It's common function for using the token
function withOutToken(apiName, data) {
  return axios({
    method: "POST",
    url: `${BASE_URL + apiName}`,
    headers: { "Content-Type": "application/json" },
    data: data,
  }).then((handleResponse) => {
    return handleResponse;
  });
}

function withLogin(apiName, data) {
  let tokenObj = JSON.parse(localStorage.getItem("token"));
  return axios({
    method: "GET",
    url: `${BASE_URL + apiName}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.api_token}`,
    },
    data: data,
  }).then((handleResponse) => {
    return handleResponse;
  });
}

function withOutLogin(apiName) {
  return axios({
    method: "GET",
    url: `${BASE_URL + apiName}`,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((handleResponse) => {
    return handleResponse;
  });
}

// function handleResponse(response) {
//   if (response.status === 200 || response.status === 201) {
//     return response;
//   } else {
//     const error = response;
//     return Promise.reject(error);
//   }
//   return handleResponse;
// }
