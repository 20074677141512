import React, { useEffect } from 'react';
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';

const NavbarLog = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = async () => {
    // await dispatch(logoutFunction());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
      navigate("/admin/login");
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.PUBLIC_URL+'/admin/js/paper-dashboard.js';
    script.async = true;
    document.body.appendChild(script);
  }, []); 

  return (
    <>
      <nav className="navbar navbar-default">
        <div className="container-fluid">
          {/* <div className="navbar-minimize">
            <button id="minimizeSidebar" className="btn btn-fill btn-icon">
              <i className="ti-more-alt"></i>
            </button>
          </div> */}
          <div className="navbar-header">
            <button type="button" className="navbar-toggle">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar bar1"></span>
              <span className="icon-bar bar2"></span>
              <span className="icon-bar bar3"></span>
            </button>
            <a className="navbar-brand" href="/">
              Overview
            </a>
          </div>
          <div className="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link onClick={logOut}>
                  <i className="ti-power-off "></i> <p className="">Logout</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};


export default NavbarLog;
