import { vendorData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const vendorFunction = (token) => async (dispatch) =>{
    try{
      const  vendorList  = await commonService.withLogin(`/admin-vendor-list`)
      if(vendorList){
        dispatch({type: vendorData.GET_VENDOR_SUCCESS , payload: {datasss: vendorList.data}})
      }
      else{
        dispatch({type: vendorData.GET_VENDOR_FAILURE , payload: {data: vendorList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: vendorData.GET_VENDOR_FAILURE , payload: error.message})
  }
};

export default vendorFunction;
