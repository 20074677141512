import {
  userConstants,
  dashboardCount,
  forgetPassword,
  consumerData,
  vendorData,
  paymentData,
  reportData,
  feedbackData,
  reviewData,
  subscriptionData,
  userblockData,
  logoutData,
  detailsData,
  signupConstants,
  extraConstants,
  googleConstants,
  varificationConstants,
  reasonReportData,
  editUserConst,
  clearEditUserConst,
  eventTypeConst,
  RegionConst,
  categoryData,
  VendorAnalyticsConst,
  ConsumerAnalyticsConst,
  UseLoginLogsConst,
  eventBlockConst,
  eventListConst,
  eventDetailConst,
  chatLogsConst,
} from "../constants/user.constants";

export const authenticationReducer = (state = { authuser: [] }, action) => {
  switch (action.type) {
    case userConstants.GET_LOGIN_SUCCESS:
      return { authuser: action.payload };
    case userConstants.GET_LOGIN_FAILURE:
      return { errorsss: action.payload };
    case userConstants.CLEAR_LOGIN_MESSAGE:
      return { authuser: [] };
    default:
      return { authuser: [] };
  }
};
export const registerReducer = (state = { authuser: [] }, action) => {
  switch (action.type) {
    case signupConstants.GET_SIGNUP_SUCCESS:
      return { authuser: action.payload };
    case signupConstants.GET_SIGNUP_FAILURE:
      return { errorsss: action.payload };

    default:
      return state;
  }
};

export const dashboardReducer = (state = { dashboard: [] }, action) => {
  switch (action.type) {
    case dashboardCount.GET_DASHBOARD_SUCCESS:
      return { dashboard: action.payload };
    case dashboardCount.GET_DASHBOARD_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const forgetReducer = (state = { forget: [] }, action) => {
  switch (action.type) {
    case forgetPassword.GET_FORGET_SUCCESS:
      return { forget: action.payload };
    case forgetPassword.GET_FORGET_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const consumerReducer = (state = { consumer: [] }, action) => {
  switch (action.type) {
    case ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_SUCCESS:
      return { consumerAnalytics: [] };
    case consumerData.GET_CONSUMER_SUCCESS:
      return { consumer: action.payload };
    case consumerData.GET_CONSUMER_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const eventListReducer = (state = { eventList: [] }, action) => {
  switch (action.type) {
    case eventListConst.GET_EVENTLIST_SUCCESS:
      return { eventList: action.payload };
    case eventListConst.GET_EVENTLIST_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const eventDetailReducer = (state = { eventDetail: [] }, action) => {
  switch (action.type) {
    case eventDetailConst.GET_EVENTDETAIL_SUCCESS:
      return { eventDetail: action.payload };
    case eventDetailConst.GET_EVENTDETAIL_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const chatLogsReducer = (state = { chatLogs: [] }, action) => {
  switch (action.type) {
    case chatLogsConst.GET_CHATLOGS_SUCCESS:
      return { chatLogs: action.payload };
    case chatLogsConst.GET_CHATLOGS_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const vendorReducer = (state = { vendor: [] }, action) => {
  switch (action.type) {
    case VendorAnalyticsConst.GET_VENDORANALYTICS_SUCCESS:
      return { vendorAnalytics: [] };
    case vendorData.GET_VENDOR_SUCCESS:
      return { vendor: action.payload};
    case vendorData.GET_VENDOR_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const editUserReducer = (state = { editUser: [] }, action) => {
  switch (action.type) {
    case editUserConst.GET_EDITUSER_SUCCESS:
      return { editUser: action.payload };
    case editUserConst.GET_EDITUSER_FAILURE:
      return { errorsss: action.payload };
    case clearEditUserConst.CLEAR_EDITUSER_MESSAGE:
      return { editUser: [], error: "" };
    default:
      return state;
  }
};

export const paymentReducer = (state = { payment: [] }, action) => {
  switch (action.type) {
    case paymentData.GET_PAYMENT_SUCCESS:
      return { payment: action.payload };
    case paymentData.GET_PAYMENT_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const reportReducer = (state = { report: [] }, action) => {
  switch (action.type) {
    case reportData.GET_REPORT_SUCCESS:
      return { report: action.payload };
    case reportData.GET_REPORT_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const feedbackReducer = (state = { feedback: [] }, action) => {
  switch (action.type) {
    case feedbackData.GET_FEEDBACK_SUCCESS:
      return { feedback: action.payload };
    case feedbackData.GET_FEEDBACK_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const reviewReducer = (state = { review: [] }, action) => {
  switch (action.type) {
    case reviewData.GET_REVIEW_SUCCESS:
      return { review: action.payload };
    case reviewData.GET_REVIEW_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const subscriptionReducer = (state = { subscription: [] }, action) => {
  switch (action.type) {
    case subscriptionData.GET_SUBSCRIPTION_SUCCESS:
      return { subscription: action.payload };
    case subscriptionData.GET_SUBSCRIPTION_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const userblockReducer = (state = { userblock: [], message: "" }, action) => {
  switch (action.type) {
    case userblockData.GET_USERBLOCK_SUCCESS:
      return {
        userblock: action.payload.datasss.data,
        message: action.payload.datasss.message,
      };
    case userblockData.GET_USERBLOCK_FAILURE:
      return {
        errorsss: action.payload.datasss.data,
        message: action.payload.datasss.message,
      };
    default:
      return state;
  }
};

export const logoutReducer = (state = { logout: [] }, action) => {
  switch (action.type) {
    case logoutData.GET_USERLOGOUT_SUCCESS:
      return { logout: action.payload };
    case logoutData.GET_USERLOGOUT_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const detailsReducer = (state = { details: [] }, action) => {
  switch (action.type) {
    case vendorData.GET_VENDOR_SUCCESS:
      return { details: [] };
    case eventListConst.GET_EVENTLIST_SUCCESS:
      return { details: [] };
    case chatLogsConst.GET_CHATLOGS_SUCCESS:
      return { details: [] };
    case VendorAnalyticsConst.GET_VENDORANALYTICS_SUCCESS:
      return { vendorAnalytics: [] };
    case ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_SUCCESS:
      return { consumerAnalytics: [] };
    case detailsData.GET_DETAILS_SUCCESS:
      return { details: action.payload };
    case detailsData.GET_DETAILS_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const vendorAnalyticsReducer = (state = { vendorAnalytics : [] }, action) => {
  switch (action.type) {
    case vendorData.GET_VENDOR_SUCCESS:
      return { vendorAnalytics: [] };
    case VendorAnalyticsConst.GET_VENDORANALYTICS_SUCCESS:
      return { vendorAnalytics: action.payload };
    case VendorAnalyticsConst.GET_VENDORANALYTICS_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const consumerAnalyticsReducer = (state = { consumerAnalytics : [] }, action) => {
  switch (action.type) {
    case consumerData.GET_CONSUMER_SUCCESS:
      return { consumerAnalytics: [] };
    case ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_SUCCESS:
      return { consumerAnalytics: action.payload };
    case ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const userLoginLogsReducer = (state = { userLogs : [] }, action) => {
  switch (action.type) {
    case UseLoginLogsConst.GET_USERLOGINLOGS_SUCCESS:
      return { userLogs: action.payload };
    case UseLoginLogsConst.GET_USERLOGINLOGS_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const eventBlockReducer = (state = { eventBlock : [] }, action) => {
  switch (action.type) {
    case eventBlockConst.GET_EVENTBLOCK_SUCCESS:
      return { eventBlock: action.payload };
    case eventBlockConst.GET_EVENTBLOCK_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const extraReducer = (state = { extra: [] }, action) => {
  switch (action.type) {
    case extraConstants.GET_EXTRA_SUCCESS:
      return { extra: action.payload };
    case extraConstants.GET_EXTRA_FAILURE:
      return { errorsss: action.payload };
    default:
      return state;
  }
};

export const googleReducer = (state = { google: [], error: "" }, action) => {
  switch (action.type) {
    case googleConstants.GET_GOOGLE_SUCCESS:
      return { google: action.payload };
    case googleConstants.GET_GOOGLE_FAILURE:
      return { error: action.payload };
    default:
      return state;
  }
};

export const varificationReducer = (
  state = { varification: [], error: "" },
  action
) => {
  switch (action.type) {
    case varificationConstants.GET_VARIFICATION_SUCCESS:
      return { varification: action.payload };
    case varificationConstants.GET_VARIFICATION_FAILURE:
      return { error: action.payload };
    default:
      return state;
  }
};

export const reasonReducer = (state = { reason: [] }, action) => {
  switch (action.type) {
    case reasonReportData.GET_REASONREPORT_SUCCESS:
      return { report: action.payload };
    case reasonReportData.GET_REASONREPORT_FAILURE:
      return { error: action.payload };
    default:
      return state;
  }
};

export const eventTypeReducer = (state = { eventType: [], error: "" }, action) => {
  switch (action.type) {
      case eventTypeConst.GET_EVENTTYPE_SUCCESS:
          return { eventType: action.payload };
      case eventTypeConst.GET_EVENTTYPE_FAILURE:
          return { error: action.payload };
      default:
          return state;
  }
}

export const RegionReducer = (state = { region: [], error: "" }, action) => {
  switch (action.type) {
      case RegionConst.GET_REGION_SUCCESS:
          return { region: action.payload };
      case RegionConst.GET_REGION_FAILURE:
          return { error: action.payload };
      default:
          return state;
  }
}

export const CategoryReducer = (state = { category: [], error: "" }, action) => {
  switch (action.type) {
      case categoryData.GET_CATEGORY_SUCCESS:
          return { category: action.payload };
      case categoryData.GET_CATEGORY_FAILURE:
          return { error: action.payload };
      default:
          return state;
  }
}