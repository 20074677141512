import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import vendorFunction from "../../actions/vendorAction";
import userblockFunction from "../../actions/userblockAction";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import moment from 'moment-timezone';
import Loader from 'react-js-loader';
const VendorList = () => {
  const [newVendor, setNewVendor] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { vendor } = useSelector((state) => state.vendor);
  const statedata = useSelector((state) => state.userblock);

  useEffect(() => {
    dispatch(vendorFunction());
  }, [dispatch]);

  useEffect(()=>{
    if(statedata){
      dispatch(vendorFunction());
    }
  },[dispatch,statedata])

  const handleUserAction = (userid, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: status === '0' ? "You want unblock user" : "You want block user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: status === '0' ? "unblock!" : "block!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(userblockFunction(userid, status));
        var msg = status === '0' ? "user unblocked successfully!" : "user blocked successfully!";
        toast.success(msg);
      }
    });
  };

  const detailFunction = (id) => {
  };
  useEffect(() => {
    if (vendor && vendor.datasss && vendor.datasss.data) {
      setNewVendor(vendor.datasss.data);
      setLoading(false)
    }
  }, [vendor]);

  return (
    newVendor && newVendor.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
        <ToastContainer />
        {loading ? (
          <div className={"item loader-item"}>
              <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
          </div>
        ) : (
          <div className="main-panel">
            <NavbarLog />
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="header">
                        <h4 className="title">Vendors List</h4>
                      </div>
                      <div className="card-content">
                        <div className="toolbar"></div>
                        <div className="fresh-datatables content table-responsive table-full-width">
                        {newVendor.length > 0 ? ( 
                          <table id="datatables" className="table table-striped table-no-bordered table-hover" cellSpacing="0" width="100%">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Full Name</th>
                                <th>Business Name</th>
                                <th>Email</th>
                                <th>Favourite Count</th>
                                <th>Chat Threads</th>
                                <th>Reels Count</th>
                                <th>Link click count</th>
                                <th>Created Date</th>
                                <th>Last Login</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {newVendor.length > 0 && (
                              newVendor.map((user, index) => (
                                <tr key={user.id}>
                                  <td>{index+1}</td>
                                  <td>{user.first_name +' '+ user.last_name}</td>
                                  <td>{(user.business_name) ? user.business_name : '-'}</td>
                                  <td>{(user.email) ? user.email : ''}</td>
                                  <td>{(user.vendor_fav_count) ? user.vendor_fav_count : '0'}</td>
                                  <td>{(user.vendor_chat_count) ? user.vendor_chat_count : '0'}</td>
                                  <td>{(user.reels_sum_view_count) ? user.reels_sum_view_count : '0'}</td>
                                  <td>{(user.vendor_link_sum_view_count) ? user.vendor_link_sum_view_count : '0'}</td>
                                  <td>{user?.created_at ? moment(user.created_at).format('YYYY-MM-DD') : '-'}</td>
                                  <td>{user?.last_login ? moment(user?.last_login).format("YYYY-MM-DD HH:mm:ss") : '-'}</td>
                                  <td>
                                      <>
                                        <Link style={{marginTop: "3px"}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="User Block" className={user.is_blocked === '0' ? "btn btn-danger" : "btn btn-info"}
                                          onClick={() =>
                                            handleUserAction(user.id, user.is_blocked === '0' ? "1" : "0")
                                          }
                                        >
                                          <i className="fa fa-ban" aria-hidden="true" ></i>
                                        </Link>{" "}
                                        <Link style={{marginTop: "3px"}} to={`/admin/vendor-analytics/${user.id}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Vendor Analytics" className="btn btn-primary">
                                          <i className="fa fa-area-chart" aria-hidden="true"></i>
                                        </Link>{" "}
                                        <Link style={{marginTop: "3px"}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="User Info" onClick={detailFunction(user.id)} to={`/admin/vendor-details/${user.id}`} className="btn btn-success">
                                          View
                                        </Link>
                                      </>
                                  </td>
                                </tr>
                              ))
                            )}
                            </tbody>
                          </table>
                          ):(
                            <p>No data found.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <Footer />
          </div>
        )}
      </>
    )
  );
};

export default VendorList;
