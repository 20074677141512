import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import reportFunction from "../../actions/reportAction";
import { Button } from "reactstrap";
import postInactiveFunction from "../../actions/postInactiveAction";
import reportReasonListAction from "../../actions/reportReasonListAction";
import userblockFunction from "../../actions/userblockAction";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import $ from "jquery";
import Loader from 'react-js-loader';
import moment from 'moment';

const Reports = () => {
  const [newReport, setNewReport] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { report } = useSelector((state) => state.report);
  const { reason } = useSelector((state) => state);

  useEffect(() => {
    dispatch(reportFunction());
    dispatch(reportReasonListAction());
  }, [dispatch]);
  useEffect(() => {
    if (report && report.datasss && report.datasss.data) {
      setNewReport(report.datasss.data);
      setLoading(false)
    }
  }, [report]);

  useEffect(() => {
    if (reason && reason.report && reason.report.datasss && reason.report.datasss.data) {
      setReasonList(reason.report.datasss.data);
      setLoading(false)
    }
  }, [reason]);

  const handleUserBlockAction = async (userid, status) => {
    let confirmationText, successMessage;
  
    if (status == 1) {
      confirmationText = "You want to block the user";
      successMessage = "User blocked successfully";
    } else {
      confirmationText = "You want to unblock the user";
      successMessage = "User unblocked successfully";
    }
  
    const result = await Swal.fire({
      title: "Are you sure?",
      text: confirmationText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: status == 1 ? "Block!" : "Unblock!",
    });
  
    if (result.isConfirmed) {
      try {
        await dispatch(userblockFunction(userid, status));
        dispatch(reportFunction());
        toast(successMessage);
      } catch (error) {
        // Handle error if necessary
      }
    }
  };
  
  const handleUserAction = (userid, post_id, report_type, status) => {

    if (status == 1) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want inactive this post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(postInactiveFunction(userid, post_id, report_type, status))
          .then(() => {
            dispatch(reportFunction());
            toast("Post inactive successfully");
          })
          .catch((error) => {
            // Handle error if necessary
          });
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want active this post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(postInactiveFunction(userid, post_id, report_type, status))
          .then(() => {
            dispatch(reportFunction());
            toast("Post active successfully");
          })
          .catch((error) => {
            // Handle error if necessary
          });
        }
      });
    }
  };

  return (
    newReport && newReport.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
       {loading ? (
          <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
      </div>
            ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="title">Reports</h4>
                  <br />
                  <div className="card">
                    <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables content table-responsive table-full-width">
                        {newReport.length > 0 ? (
                          <table
                            id="datatables"
                            className="table table-striped table-no-bordered table-hover"
                            cellSpacing="0"
                            width="100%"
                          >
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Reported By</th>
                                <th>Reported Against</th>
                                <th>Report Reasons</th>
                                <th>Content Type</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {newReport.length > 0
                                ? newReport.map((x, i) => {
                                    return (
                                      <tr>
                                        <td>{i+1}</td>
                                        <td>
                                          {x?.report_by[0]?.first_name}{" "}
                                          {x?.report_by[0]?.last_name}
                                        </td>
                                        <td>
                                          {x?.report_against[0]?.first_name}{" "}
                                          {x?.report_against[0]?.last_name}
                                        </td>
                                        {/* <td>{x.report_reasons}</td> */}
                                        <td>
                                         <div className="height-fix">
                                        {JSON.parse(x.report_reasons).map((reasonId, index) => {
                                            const reportReason = reasonList.find(
                                              (reason) => reason.id === reasonId
                                            );
                                            return (
                                              <li key={index}>{reportReason ? reportReason.reason : ""}</li>
                                            );
                                          })}
                                          </div>
                                        </td>
                                        <td>{x?.report_type[0]?.title}</td>
                                        <td>{x?.created_at ? moment(x.created_at).format('YYYY-MM-DD') : '-'}</td>
                                        <td>
                                          {" "}
                                          <>
                                            {x?.report_against[0]?.is_blocked ==
                                            0 ? (
                                          
                                                <Button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title="User Block"
                                                  className="btn btn-danger report-btn-active"
                                                  onClick={() =>
                                                    handleUserBlockAction(
                                                      x?.report_against[0]?.id,
                                                      "1"
                                                    )
                                                  }
                                                >
                                                  Block
                                                </Button>
                                            
                                            ) : (
                                            
                                                <Button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title="User Unblock"
                                                  className="btn btn-info report-btn-inactive"
                                                  onClick={() =>
                                                    handleUserBlockAction(
                                                      x?.report_against[0]?.id,
                                                      "0"
                                                    )
                                                  }
                                                >
                                                  Unblock
                                                </Button>
                                            
                                            )}
                                            {x.status == 0 &&
                                            x.report_type[0].title !==
                                              "user report" ? (
                                              <>
                                                <Button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title="Post Active"
                                                  className="btn btn-danger report-btn-active"
                                                  onClick={() =>
                                                    handleUserAction(
                                                      x.id,
                                                      x.post_id,
                                                      x.report_type_id,
                                                      "1"
                                                    )
                                                  }
                                                >
                                                  Active
                                                </Button>{" "}
                                              </>
                                            ) : (
                                              <>
                                                {x.report_type[0].title !==
                                                "user report" ? (
                                                  <>
                                                    <Button
                                                      data-bs-toggle="tooltip"
                                                      data-bs-placement="bottom"
                                                      title="Post Inactive"
                                                      className="btn btn-info report-btn-inactive"
                                                      onClick={() =>
                                                        handleUserAction(
                                                          x.id,
                                                          x.post_id,
                                                          x.report_type_id,
                                                          "0"
                                                        )
                                                      }
                                                    >
                                                      Inactive
                                                    </Button>{" "}
                                                  </>
                                                ) : (
                                                  <>
                                                    <Button
                                                      data-bs-toggle="tooltip"
                                                      disabled
                                                      data-bs-placement="bottom"
                                                      title="Post Inactive"
                                                      className="btn btn-info report-btn-diabled"
                                                      onClick={() =>
                                                        handleUserAction(
                                                          x.id,
                                                          x.post_id,
                                                          x.report_type_id,
                                                          "0"
                                                        )
                                                      }
                                                    >
                                                      Diabled
                                                    </Button>{" "}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </tbody>
                          </table>
                        ) : (
                          <p>No data found.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
               )}
      </>
    )
  );
};

export default Reports;
