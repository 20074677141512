// import  axios  from "axios";
import {subscriptionData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const subscriptionFunction = () => async (dispatch) =>{
    try{
      // dispatch({ type:userCo , payload: { loading: true } })
      const  subscriptionList  = await commonService.withLogin(`/subscription-history`)
      // console.log("dsfsdfsdfsdf" ,consumerList.data)
      if(subscriptionList){
        dispatch({type: subscriptionData.GET_SUBSCRIPTION_SUCCESS , payload: {datasss: subscriptionList.data}})
      }
      else{
        dispatch({type: subscriptionData.GET_SUBSCRIPTION_FAILURE , payload: {data: subscriptionList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: subscriptionData.GET_SUBSCRIPTION_FAILURE , payload: error.message})
  }
}

export default subscriptionFunction;