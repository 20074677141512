import { commonService } from "../services/common.service";
import { VendorAnalyticsConst } from "../constants/user.constants";

 const VendorAnalyticsAction = (id) => async (dispatch) =>{
    try{
      const  VendorAnalytics  = await commonService.withLogin(`/vendor-analytics/${id}`)
      if(VendorAnalytics){
        dispatch({type: VendorAnalyticsConst.GET_VENDORANALYTICS_SUCCESS , payload: {analytics : VendorAnalytics.data}})
      }
      else{
        dispatch({type: VendorAnalyticsConst.GET_VENDORANALYTICS_FAILURE , payload: {data: VendorAnalytics.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: VendorAnalyticsConst.GET_VENDORANALYTICS_FAILURE , payload: error.message})
  }
};

export default VendorAnalyticsAction;