import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Loader from "react-js-loader";
import $ from "jquery";
import eventDetailAction from "../../actions/eventDetailAction";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";

const EventDetail = () => {
    const { id } = useParams();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(eventDetailAction(id));
    }, [dispatch, id]);

    const { eventDetail } = useSelector((state) => state.eventDetail);
    useEffect(() => {
        if (eventDetail && eventDetail.datasss && eventDetail.datasss.data) {
            setEvents(eventDetail.datasss.data);
            setLoading(false);
        }
    }, [eventDetail]);

    return (
        events && events.length > 0
            ? $(document).ready(function () {
                $("#datatables").DataTable();
            })
            : null,
        (
            <>
                {loading ? (
                    <div className={"item loader-item"}>
                        <Loader
                            type="spinner-circle"
                            bgColor={"#66615B"}
                            color={"#66615B"}
                            size={100}
                        />
                    </div>
                ) : (
                    <div className="main-panel">
                        <NavbarLog />
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10">
                                        <ToastContainer />
                                        <div className="card">
                                            <div className="header">
                                                <h4 className="title">Host Details</h4>
                                            </div>
                                            <div className="content">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>First Name</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.user?.first_name || ''}/> */}
                                                                <p className="form-control border-input">{(events?.user?.first_name) ? events?.user?.first_name : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Last Name</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.user?.last_name || ''}/> */}
                                                                <p className="form-control border-input">{(events?.user?.last_name) ? events?.user?.last_name : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Email Address</label>
                                                                {/* <input type="email" className="form-control border-input" placeholder="N/A" defaultValue={events?.user?.email || ''}/> */}
                                                                <p className="form-control border-input">{(events?.user?.email) ? events?.user?.email : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h4 className="title" style={{ padding: "20px 0 10px 0" }}>Event Details</h4>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Event Name</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.event_name || ''}/> */}
                                                                <p className="form-control border-input">{(events?.event_name) ? events?.event_name : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Start Date</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.event_from_date || ''} /> */}
                                                                <p className="form-control border-input">{events?.event_from_date ? moment(events.event_from_date).format('DD-MM-YYYY') : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>End Date</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.event_to_date || ''} /> */}
                                                                <p className="form-control border-input">{events?.event_to_date ? moment(events.event_to_date).format('DD-MM-YYYY') : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Number Of Guest</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.num_of_guest || ''} /> */}
                                                                <p className="form-control border-input">{(events?.num_of_guest) ? events?.num_of_guest : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Status</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.num_of_guest || ''} /> */}
                                                                {/* <p className="form-control border-input">{(events?.num_of_guest) ? events?.num_of_guest : 'N/A'}</p> */}

                                                                {events?.event_to_date && new Date(events.event_to_date) < new Date() ?
                                                                    (<p><strong className="form-control border-input" style={{color : "#a94442"}}>Expired</strong></p>)
                                                                    :
                                                                    (<p><strong className="form-control border-input" style={{color : "#3c763d"}}>Active</strong></p>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div className="form-group">
                                                                <label>Location</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.location || ''}/> */}
                                                                <p className="form-control border-input">{(events?.location) ? events?.location : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Event Type</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.event_budget || ''}/> */}
                                                                <p className="form-control border-input">{(events?.event_type) ? events?.event_type : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>Event Budget</label>
                                                                {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.event_budget || ''}/> */}
                                                                <p className="form-control border-input">{(events?.event_budget) ? '$'+events?.event_budget : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Event Category</label>
                                                                <select className="form-control border-input">
                                                                    {events?.event_category?.length > 0 ? (
                                                                        events.event_category.map((category) => (
                                                                            <option key={category?.id} value={category?.category?.category_id}>
                                                                                {category?.category?.category_name}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="N/A">N/A</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Event Region</label>
                                                                    {/* <input type="text" className="form-control border-input" placeholder="N/A" defaultValue={events?.event_region?.name || ''}/> */}
                                                                    <p className="form-control border-input">{(events?.event_region) ? events?.event_region?.name : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Phone Number</label>
                                                                {/* <input type="number" className="form-control border-input" placeholder="N/A" defaultValue={events?.phone_no || ''}/> */}
                                                                <p className="form-control border-input">{(events?.phone_no) ? events?.phone_no : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Event Description</label>
                                                                <textarea rows="5" className="form-control border-input no-scrollbar" placeholder="N/A" defaultValue={(events?.event_description) ? events?.event_description : ''} ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {/* <div className="text-center">
                                                            <button type="submit" className="btn btn-info btn-fill btn-wd">Update Profile</button>
                                                        </div> */}
                                                    <div className="clearfix"></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {events?.event_photos && events.event_photos.length > 0 ? (
                                        <div className="col-lg-7">
                                            <div className="card">
                                                <div className="card-content photos-section">
                                                    <div className="admin-photos">
                                                        <h4 className="common-label">Event Photos</h4>
                                                    </div>
                                                    <hr />
                                                    <div className="vendor-event-carousel">
                                                        <OwlCarousel className="swiper-slide owl-carousel owl-theme owl-carousel-one owl-loaded owl-drag" margin={30} >
                                                            {events.event_photos?.map((photo) => {
                                                                return (
                                                                    <>
                                                                    <div className="item" key={photo.id}>
                                                                        <span className="portfolio-slider">
                                                                            <img src={photo.photos} style={{ height: "100px" }} alt="eventimage"/>
                                                                        </span>
                                                                    </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </OwlCarousel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                    {(events?.event_file ) ? (
                                        <div className="col-lg-3">
                                            <div className="card">
                                                <div className="card-content photos-section">
                                                    <div className="admin-photos">
                                                        <h4 className="common-label">Event File</h4>
                                                    </div>
                                                    <hr />
                                                    
                                                        <div className="profile-cover-section">
                                                            <div className="avatar-upload-sec profile-avtar-sec">
                                                                <span>
                                                                    <iframe src={events?.event_file ? events?.event_file : ''}  title="Event File" style={{height:'100%'}} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                )}
            </>
        )
    );
};

export default EventDetail;
