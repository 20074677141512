import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="copyright pull-right">
            Copyright &copy; {new Date().getFullYear()}, made with{" "}
            <i className="fa fa-heart heart"></i> by{" "}
            <a href="https://intuitionsoftech.com.au/">Intuition SofTech</a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
