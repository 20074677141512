import React from "react";
import "./App.css";
import ContentOverview from "./components/dashboard/overview/ContentOverview";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./components/pages/login/LoginPage";
import SidebarLayout from "./SidebarLayout";
import ConsumerList from "./components/consumer/consumerList";
import VendorList from "./components/vendor/Vendor";
import FeedbackList from "./components/feedback/Feedback";
import Reports from "./components/report/Report";
import SubscriptionDetails from "./components/susbcription/SubscriptionDetails";
import PaymentHistory from "./components/payment-history/PaymentHistory";
import ReviewList from "./components/review/Review";
import ConsumerDetails from "./components/consumer/consumerDetails";
import VendorDetails from "./components/vendor/vendorDetails";
import { ToastContainer } from "react-toastify";
import { Navigate } from "react-router";
import Setting from "./components/setting/Setting";
import VendorAnalytics from "./components/vendor/vendorAnalytics.js/vendorAnalytics";
import ConsumerAnalytics from "./components/consumer/consumerAnalytics";
import EventList from "./components/events/eventList";
import EventDetail from "./components/events/eventDetails";
import ChatLogs from "./components/pages/utils/chatLogs";

function App() {
  const user = localStorage.getItem("token");
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route element={<SidebarLayout />}>
          <Route path="*" element={<Navigate to='/admin/login' />}></Route>
          <Route index path="/admin/dashboard" element={<ContentOverview />}></Route>
          <Route path="/admin/consumer" element={<ConsumerList />}></Route>
          <Route path="/admin/chat-logs" element={<ChatLogs />}></Route>
          <Route path="/admin/events" element={<EventList />}></Route>
          <Route path="/admin/event-detail/:id" element={<EventDetail />}></Route>
          <Route path="/admin/vendor" element={<VendorList />}></Route>
          <Route path="/admin/feedback" element={<FeedbackList />}></Route>
          <Route path="/admin/review" element={<ReviewList />}></Route>
          <Route path="/admin/setting" element={<Setting />}></Route>
          <Route path="/admin/report" element={<Reports />}></Route>
          <Route path="/admin/subscription" element={<SubscriptionDetails />}></Route>
          <Route path="/admin/payment-history" element={<PaymentHistory />}></Route>
          <Route path="/admin/consumer-details/:id" element={<ConsumerDetails />}></Route>
          <Route path="/admin/consumer-analytics/:id" element={<ConsumerAnalytics />}></Route>
          <Route path="/admin/vendor-details/:id" element={<VendorDetails />}></Route>
          <Route path="/admin/vendor-analytics/:id" element={<VendorAnalytics />}></Route>
        </Route>
        <Route index path="/" element={user ? <Navigate to="/admin/dashboard" /> : <LoginPage />}></Route>
        <Route path="/admin/login" element={<LoginPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
