// import  axios  from "axios";
import { chatLogsConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const chatLogsAction = () => async (dispatch) =>{
    try{
      const  chatLogs  = await commonService.withLogin(`/chat-logs`)
      if(chatLogs){
        dispatch({type: chatLogsConst.GET_CHATLOGS_SUCCESS , payload: {datasss: chatLogs.data}})
      }
      else{
        dispatch({type: chatLogsConst.GET_CHATLOGS_FAILURE , payload: {data: chatLogs.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: chatLogsConst.GET_CHATLOGS_FAILURE , payload: error.message})
  }
}

export default chatLogsAction;