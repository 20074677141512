import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import logoutFunction from "../../../actions/logoutAction";
import { useNavigate } from "react-router";

const Sidebar = () => {
  // const dispatch = useDispatch()

  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  // const isLogout = async () => {
  //   // console.log(email, password)
  //   await dispatch(logoutFunction())
  //   navigate("/login")

  // }
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    if (localStorage.getItem("userData")) {
      const users = JSON.parse(localStorage.getItem("userData"));
      setUser(users.data.data.user);
    }
  }, [navigate]);

  return (
    <>
      <div
        className="sidebar"
        // data-background-color="brown"
        data-active-color="danger"
      >
        <div className="logo">
          <a href="/" className="simple-text logo-mini">
            OZGIG
          </a>
          <a href="/" className="simple-text logo-normal">
            <img src="/img/favicon.png" alt="ozgigs" style={{ width: 30 }} />{" "}
            OZGIG ADMIN
          </a>
        </div>
        <div className="sidebar-wrapper">
          <div className="user">
            <div className="info">
              <div className="photo">
                {user && user.profile ? (
                  <img src={user.profile} alt="images" />
                ) : (
                  <img src="../assets/img/faces/face-2.jpg" alt="images" />
                )}
              </div>
              <a href="/">
                <span>
                  {user ? user.first_name + " " + user.last_name : "Admin"}
                </span>
              </a>
            </div>
          </div>
          <ul className="nav">
            <li className="">
              <Link to="/admin/dashboard">
                <span className="sidebar-mini">
                  <i className="ti-dashboard"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Dashboard</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/consumer">
                <span className="sidebar-mini">
                  <i className="ti-user"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Consumers</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/vendor">
                <span className="sidebar-mini">
                  <i className="ti-id-badge"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Vendors</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/events">
                <span className="sidebar-mini">
                  <i className="ti-calendar"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Events</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/payment-history">
                <span className="sidebar-mini">
                  <i className="ti-money"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Payment History</p>
                </span>
              </Link>
            </li>

            <li>
              <Link to="/admin/subscription">
                <span className="sidebar-mini">
                  <i className="ti-wallet"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Subscription</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/report">
                <span className="sidebar-mini">
                  <i className="ti-receipt"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Report</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/feedback">
                <span className="sidebar-mini">
                  <i className="ti-help-alt"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Feedback</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/review">
                <span className="sidebar-mini">
                  <i className="ti-help-alt"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Review</p>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/setting">
                <span className="sidebar-mini">
                  <i className="fa fa-cog"></i>
                </span>
                <span className="sidebar-normal">
                  <p>Setting</p>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
