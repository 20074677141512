import { configureStore } from "@reduxjs/toolkit";
import {
  authenticationReducer,
  consumerReducer,
  eventListReducer,
  eventBlockReducer,
  eventDetailReducer,
  dashboardReducer,
  detailsReducer,
  extraReducer,
  feedbackReducer,
  reviewReducer,
  forgetReducer,
  googleReducer,
  logoutReducer,
  paymentReducer,
  reportReducer,
  // signupReducer,
  subscriptionReducer,
  userblockReducer,
  registerReducer,
  vendorReducer,
  varificationReducer,
  reasonReducer,
  editUserReducer,
  eventTypeReducer,
  RegionReducer,
  CategoryReducer,
  vendorAnalyticsReducer,
  consumerAnalyticsReducer,
  userLoginLogsReducer,
  chatLogsReducer
} from "./reducers/reducer";

const store = configureStore({
  reducer: {
    user: authenticationReducer,
    consumer : consumerReducer,
    chatLogs : chatLogsReducer,
    eventList : eventListReducer,
    eventDetail : eventDetailReducer,
    eventBlock : eventBlockReducer,
    logout: logoutReducer,
    forget: forgetReducer,
    vendor: vendorReducer,
    dashboard: dashboardReducer,
    payment: paymentReducer,
    report: reportReducer,
    feedback: feedbackReducer,
    review: reviewReducer,
    subscription: subscriptionReducer,
    userblock: userblockReducer,
    details: detailsReducer,
    vendorAnalytics   : vendorAnalyticsReducer,
    consumerAnalytics : consumerAnalyticsReducer,
    userLoginLogs : userLoginLogsReducer,
    signup: registerReducer,
    // register: signupReducer,
    extra: extraReducer,
    google: googleReducer,
    varification:varificationReducer,
    reason: reasonReducer,
    editUser: editUserReducer,
    eventType: eventTypeReducer,
    region: RegionReducer,
    category: CategoryReducer
  },
});
export default store;
