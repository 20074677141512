// import  axios  from "axios";
import { reportData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const reportFunction = () => async (dispatch) => {
  try {
    
    const reportList = await commonService.withLogin(`/report-list`);
    if (reportList) {
      dispatch({
        type: reportData.GET_REPORT_SUCCESS, payload: { datasss: reportList.data },
      });
    }
    else {
      dispatch({
        type: reportData.GET_REPORT_FAILURE, payload: { data: reportList.data },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error}`);
    dispatch({ type: reportData.GET_REPORT_FAILURE, payload: error.message });
  }
};

export default reportFunction;
