import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import subscriptionFunction from "../../actions/subscriptionAction";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Loader from 'react-js-loader';
import moment from 'moment';

const SubscriptionDetails = () => {
  const [newSubscription, setNewSubscription] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { subscription } = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(subscriptionFunction());
  }, [dispatch]);
  useEffect(() => {
    if (subscription && subscription.datasss && subscription.datasss.data) {
      setNewSubscription(subscription.datasss.data);
      setLoading(false)
    }
  }, [subscription]);


  return (
    newSubscription && newSubscription.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
      {loading ? (
          <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
      </div>
            ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="title">Subscriptions</h4>
                  <br />
                  <div className="card">
                    <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables content table-responsive table-full-width">
                      {newSubscription.length > 0 ? ( <table
                          id="datatables"
                          className="table table-striped table-no-bordered table-hover"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Selected Plan</th>
                              <th>Price</th>
                              <th>Status</th>
                              <th>Subscription Date</th>
                              <th>Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {newSubscription.length > 0
                              ? newSubscription.map((x, i) => {
                                  return (
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{x.user[0].first_name} {x.user[0].last_name}</td>
                                      <td>{x.user[0].email}</td>
                                      <td>{x.price_details.recurring.interval}</td>
                                      <td>${x.price_details.unit_amount/100}</td>
                                      <td>{x?.expiry_date && new Date(x?.expiry_date) < new Date() ?
                                          (<button className="btn btn-normal btn-danger">Expired</button>)
                                          :
                                          (<button className="btn btn-normal btn-success">Active</button>)
                                      }</td>
                                      <td>{x?.created_at ? moment(x.created_at).format('YYYY-MM-DD') : '-'}</td>
                                      <td>{x?.expiry_date ? moment(x.expiry_date).format('YYYY-MM-DD') : '-'}</td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                        ):(
                        <p>No data found.</p>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         )}
      </>
    )
  );
};

export default SubscriptionDetails;
