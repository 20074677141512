import { commonService } from "../services/common.service";
import { editUserConst,clearEditUserConst } from "../constants/user.constants";

const editUserAction = (data) => async (dispatch) => {
    try{
        const editUser = await commonService.withToken('/admin-edit-user',data);
        if (editUser) {
            dispatch({ type: editUserConst.GET_EDITUSER_SUCCESS, payload: { datasss: editUser.data } });
        }
        else {
            dispatch({ type: editUserConst.GET_EDITUSER_FAILURE, payload: { data: editUser.data } });
        }
    }
    catch (error) {
        dispatch({ type: editUserConst.GET_EDITUSER_FAILURE, payload: error.message })
    }
}

export const clearEditUserMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearEditUserConst.CLEAR_EDITUSER_MESSAGE, payload: [] })
    } catch (err) {
        console.log(`Error while calling login api ${err}`);
    }
}

export default editUserAction;
