// import  axios  from "axios";
import {feedbackData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const feedbackFunction = () => async (dispatch) =>{
    try{
      // dispatch({ type:userCo , payload: { loading: true } })
      const  feedbackList  = await commonService.withLogin(`/feedback-list`)
      if(feedbackList){
        dispatch({type: feedbackData.GET_FEEDBACK_SUCCESS , payload: {datasss: feedbackList.data}})
      }
      else{
        dispatch({type: feedbackData.GET_FEEDBACK_FAILURE , payload: {data: feedbackList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: feedbackData.GET_FEEDBACK_FAILURE , payload: error.message})
  }
}

export default feedbackFunction;