import { Outlet } from "react-router-dom";
import Sidebar from "./components/dashboard/Sidebar";

const SidebarLayout = () => (
  <>
    <Sidebar />
    <Outlet />
  </>
);

export default SidebarLayout;
