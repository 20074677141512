// import  axios  from "axios";
import {reviewData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const reviewAction = () => async (dispatch) =>{
    try{
      // dispatch({ type:userCo , payload: { loading: true } })
      const  reviewList  = await commonService.withLogin(`/admin-review-list`)
      if(reviewList){
        dispatch({type: reviewData.GET_REVIEW_SUCCESS , payload: {datasss: reviewList.data}})
      }
      else{
        dispatch({type: reviewData.GET_REVIEW_FAILURE , payload: {data: reviewList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: reviewData.GET_REVIEW_FAILURE , payload: error.message})
  }
}

export default reviewAction;