import React, { useState, useEffect } from "react";
import Footer from "../../Footer";
import NavbarLog from "../../pages/login/NavbarLog";
import dashboardData from "../../../actions/dasboardCount";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import consumerFunction from "../../../actions/consumerAction";
import vendorFunction from "../../../actions/vendorAction";
import Loader from 'react-js-loader';
const ContentOverview = () => {
  const [newDashboard, setNewDashboard] = useState([]);
  const [newConsumer, setNewConsumer] = useState([]);
  const [newVendor, setNewVendor] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { dashboard } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(dashboardData());
    dispatch(consumerFunction());
    dispatch(vendorFunction());
  }, [dispatch]);

  const { vendor } = useSelector((state) => state.vendor);
  useEffect(() => {
    if (vendor && vendor.datasss && vendor.datasss.data) {
      setNewVendor(vendor.datasss.data);
      setLoading(false)
    }
  }, [vendor]);
  useEffect(() => {
    if (dashboard && dashboard.datasss && dashboard.datasss.data)
      setNewDashboard(dashboard.datasss.data);
      setLoading(false)
  }, [dashboard]);
  useEffect(() => {
    if (dashboard && dashboard.datasss && dashboard.datasss.data)
      setNewDashboard(dashboard.datasss.data);
  }, [dashboard]);

  const { consumer } = useSelector((state) => state.consumer);
  useEffect(() => {
    if (consumer && consumer.datasss && consumer.datasss.data) {
      setNewConsumer(consumer.datasss.data);
      setLoading(false)
    }
  }, [consumer]);

  return (
    <>
      <div className="main-panel">
          <NavbarLog />
            <div className="content">
                <div className="container-fluid">
                    {newDashboard && (
                        <>
                            <ToastContainer />
                            <>
                              {loading ? (
                                <div className={"item loader-item"}>
                                  <Loader
                                    type="spinner-circle"
                                    bgColor={"#66615B"}
                                    color={"#66615B"}
                                    size={100}
                                  />
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-warning text-center">
                                              <i
                                                className="fa fa-user-circle-o"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Total Consumers</p>
                                              {newDashboard && newDashboard.total_consumer
                                                ? newDashboard.total_consumer
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <div
                                            className="pull-right"
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to="/admin/consumer"
                                              className="text text-warning"
                                            >
                                              <i
                                                className="fa fa-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <span>View All Consumers</span>
                                          <div
                                            className="my-inline-block"
                                            id="campaign-name4"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-info text-center">
                                              <i className="fa fa-user-secret"></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Total Vendors</p>
                                              {newDashboard && newDashboard.total_vendor
                                                ? newDashboard.total_vendor
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <div
                                            className="pull-right"
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to="/admin/vendor"
                                              className="text text-info"
                                            >
                                              <i
                                                className="fa fa-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <span>View All Vendors</span>
                                          <div
                                            className="my-inline-block"
                                            id="campaign-name4"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-danger text-center">
                                              <i className="fa fa-users"></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Reported Users</p>
                                              {newDashboard && newDashboard.reported
                                                ? newDashboard.reported
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <div
                                            className="pull-right"
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to="/admin/report"
                                              className="text text-danger"
                                            >
                                              <i
                                                className="fa fa-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <span>View All Reports</span>
                                          <div
                                            className="my-inline-block"
                                            id="campaign-name4"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-success text-center">
                                              <i
                                                className="fa fa-address-card"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Active Users</p>
                                              {newDashboard && newDashboard.active_user
                                                ? newDashboard.active_user
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <div
                                            className="pull-right"
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to="/admin/consumer"
                                              className="text text-success"
                                            >
                                              <i
                                                className="fa fa-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <span>View All Users</span>
                                          <div
                                            className="my-inline-block"
                                            id="campaign-name4"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-success text-center">
                                              <i className="fa fa-comments" aria-hidden="true"></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Total Message Thread</p>
                                              {newDashboard && newDashboard.total_thread
                                                ? newDashboard.total_thread
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <div
                                            className="pull-right"
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to="/admin/chat-logs"
                                              className="text text-success"
                                            >
                                              <i
                                                className="fa fa-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <span>View All Chat Logs</span>
                                          <div
                                            className="my-inline-block"
                                            id="campaign-name4"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-primary text-center">
                                              <i className="fa fa-link" aria-hidden="true"></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Total Clicks On Links</p>
                                              {newDashboard && newDashboard.total_links
                                                ? newDashboard.total_links
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <span>Total Clicks On Links</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-info text-center">
                                              <i className="fa fa-video-camera" aria-hidden="true"></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Total Views Of Reels</p>
                                              {newDashboard && newDashboard.total_reels
                                                ? newDashboard.total_reels
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <span>Total Views Of Reels</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6">
                                    <div className="card card-circle-chart">
                                      <div className="card-content">
                                        <div className="row">
                                          <div className="col-xs-5">
                                            <div className="icon-big icon-success text-center">
                                              <i className="fa fa-calendar" aria-hidden="true"></i>
                                            </div>
                                          </div>
                                          <div className="col-xs-7">
                                            <div className="numbers">
                                              <p>Total Number of Events</p>
                                              {newDashboard && newDashboard.total_events
                                                ? newDashboard.total_events
                                                : 0}{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <hr />
                                        <div className="stats">
                                          <div
                                            className="pull-right"
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <Link
                                              to="/admin/events"
                                              className="text text-success"
                                            >
                                              <i
                                                className="fa fa-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                          <span>View All Events</span>
                                          <div
                                            className="my-inline-block"
                                            id="campaign-name4"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="row">
                                {loading ? (
                                  <div className={"item loader-item"}>
                                    <Loader
                                      type="spinner-circle"
                                      bgColor={"#66615B"}
                                      color={"#66615B"}
                                      size={100}
                                    />
                                  </div>
                                ) : (
                                  <div className="col-md-6">
                                    <h4 className="title">Consumers List</h4>
                                    <div className="card">
                                      <div className="toolbar"></div>
                                      <div className="fresh-datatables content table-responsive table-full-width">
                                        <table
                                          id="datatables"
                                          className="table table-striped table-no-bordered table-hover"
                                          cellSpacing="0"
                                          width="100%"
                                        >
                                          <thead>
                                            <tr>
                                              <th>Full Name</th>
                                              <th>Email</th>
                                              <th>State</th>
                                              <th>Postcode</th>
                                              <th>Login Type</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {newConsumer.length > 0 && (
                                              <>
                                                {newConsumer.map((user, index) =>
                                                  index < 10 ? (
                                                      <tr key={index}>
                                                        <td>{" "}{user.first_name}{" "}{user.last_name}{" "}</td>
                                                        <td>{user.email}</td>
                                                        <td>{" "}{user?.user_information?.state }{" "}</td>
                                                        <td>{" "}{user?.user_information?.postcode}{" "}</td>
                                                        <td>{" "}{user.login_type.charAt(0).toUpperCase() + user.login_type.slice(1)}{" "}</td>
                                                      </tr>
                                                  ) : (
                                                    ""
                                                  )
                                                )}
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {loading ? (
                                  <div className={"item loader-item"}>
                                    <Loader
                                      type="spinner-circle"
                                      bgColor={"#66615B"}
                                      color={"#66615B"}
                                      size={100}
                                    />
                                  </div>
                                ) : (
                                  <div className="col-md-6">
                                    <h4 className="title">Vendor List</h4>
                                    <div className="card">
                                      <div className="toolbar"></div>
                                      <div className="fresh-datatables content table-responsive table-full-width">
                                        <table
                                          id="datatables"
                                          className="table table-striped table-no-bordered table-hover"
                                          cellSpacing="0"
                                          width="100%"
                                        >
                                          <thead>
                                            <tr>
                                              <th>Full Name</th>
                                              <th>Email</th>
                                              <th>State</th>
                                              <th>Postcode</th>
                                              <th>Login Type</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {newVendor.length > 0 && (
                                              <>
                                                {newVendor.map((user, index) =>
                                                  index < 10 ? (
                                                      <tr key={index}>
                                                        <td>{" "}{user.first_name}{" "}{user.last_name}{" "}</td>
                                                        <td>{user.email}</td>
                                                        <td>{" "}{user?.user_information?.state}{" "}</td>
                                                        <td>{" "}{user?.user_information?.postcode}{" "}</td>
                                                        <td>{user.login_type.charAt(0).toUpperCase() +user.login_type.slice(1)}{" "}</td>
                                                      </tr>
                                                  ) : (
                                                    ""
                                                  )
                                                )}
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                        </>
                    )}
                </div>
            </div>
          <Footer />
      </div>
    </>
  );
};

export default ContentOverview;
