// import  axios  from "axios";
import {detailsData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const detailsFunction = (id) => async (dispatch) =>{
    try{
      const  {data}  = await commonService.withLogin(`/user-details/${id}`)
      if(data){
        dispatch({type: detailsData.GET_DETAILS_SUCCESS , payload: {datasss: data.data}})
      }
      else{
        dispatch({type: detailsData.GET_DETAILS_FAILURE , payload: {data: data.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: detailsData.GET_DETAILS_FAILURE , payload: error.message})
  }
}

export default detailsFunction;