import React, { useState, useEffect } from "react";
import authenticationLogin from "../../../actions/login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';
import { auth } from '../../../firebase'

const LoginPage = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState("");


  // check email password validate or call login api
  const handlechange = async () => {
    if (!email || !password) {
      setError(true);
      return false;
    }
    await dispatch(authenticationLogin({ email: email, password: password }));
  };

  // hide or show password
  const togglePasswordVisibility = (type) => {
    setShowPassword(!showPassword);
  };
  
  // get the response from login api and set the response accordingly
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    const authenticatedUser = JSON.parse(localStorage.getItem("userData"));
    if (userData.authuser && userData.authuser.datasss && userData.authuser.datasss.status !== false && authenticatedUser) {
      toast(userData.authuser && userData.authuser.datasss && userData.authuser.datasss.message);
      let obj = {
        email: userData.authuser.datasss.data.user.email,
        api_token: userData.authuser.datasss.data.api_token,
      };
      localStorage.setItem("token", JSON.stringify(obj));
      signInWithEmailAndPassword(auth, email, password) // generateing firebase token if user exst in firebase
                .then((userCredential) => {
                        const access_token = userCredential.user.getIdToken()
                        access_token.then(data=>{
                            localStorage.setItem('FIREBASE_ACCESS_TOKEN',data)
                        })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.code === "auth/user-not-found") {
                        createUserWithEmailAndPassword(auth, email, password).then((userRegistered) => { // creating user user noy exst in firebase
                            const access_token = userRegistered.user.getIdToken()
                            access_token.then(data=>{
                                localStorage.setItem('FIREBASE_ACCESS_TOKEN',data);
                            })
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                });
      navigate("/admin/dashboard");
    }else {
      toast.error(userData.authuser && userData.authuser.datasss && userData.authuser.datasss.message);
    }
    // dispatch(clearLoginMsg());
  }, [navigate,userData]);

  return (
    <>
      <div className="wrapper wrapper-full-page">
        <div
          className="full-page login-page"
          // data-color=""
          // data-image="../../img/background/background-2.jpg"
        >
          <div className="content">
            <div className="container">
              <div className="row">
                <>
                  <h2 className="text-center" style={{ marginBottom: "50px" }}>
                    <img
                      src="/img/favicon.png"
                      alt="ozgigs"
                      style={{ width: 50 }}
                    />{" "}
                    OZGIG ADMIN
                  </h2>
                </>
                <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                  {/* <form > */}
                  <div
                    className="card"
                    data-background="color"
                    data-color="blue"
                  >
                    <div className="card-header">
                      <h3 className="card-title">Login</h3>
                    </div>
                    <ToastContainer />

                    <div className="card-content">
                      <div className="form-group">
                        <label>Email address</label>
                        <input
                          type="email"
                          value={email}
                          placeholder="Enter email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control input-no-border"
                        />
                        {error && !email && (
                          <span
                            style={{ color: "red" }}
                            className="invalidInput"
                          >
                            Enter Required Email
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control input-no-border"
                        />
                        <button
                            type="button"
                            onClick={() => {togglePasswordVisibility('new')}}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '70%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                border: 'none',
                                background: 'none',
                            }}
                            >
                            {showPassword ? (
                                <i className="fa fa-eye-slash" style={{ fontSize: '18px' }}></i>
                            ) : (
                                <i className="fa fa-eye" style={{ fontSize: '18px' }}></i>
                            )}
                        </button>
                        {error && !password && (
                          <span
                            style={{ color: "red" }}
                            className="invalidInput"
                          >
                            Enter Required Password
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="card-footer text-center">
                      <button
                        type="submit"
                        className="btn btn-wd "
                        onClick={handlechange}
                      >
                        Let's go
                      </button>
                      {/* <div className="forgot" style={{ marginTop: "2rem" }}>
                        <a href="/forgot-password">Forgot your password?</a>
                      </div> */}
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>

          <footer className="footer footer-transparent">
            <div className="container">
              <div className="copyright ">
                Copyright &copy; {new Date().getFullYear()}, made with{" "}
                <i className="fa fa-heart heart"></i> by{" "}
                <a href="https://intuitionsoftech.com.au/">Intuition SofTech</a>
              </div>
            </div>
          </footer>
        </div>
      </div>{" "}
    </>
  );
};

export default LoginPage;
