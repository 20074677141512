import { userConstants } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const authenticationLogin = (datass) => async (dispatch) => {
  try {
    // dispatch({ type:userCo , payload: { loading: true } })
    const userdata = await commonService.withOutToken(`/login`, datass);
    localStorage.setItem("userData", JSON.stringify(userdata));
    if (userdata) {
      dispatch({
        type: userConstants.GET_LOGIN_SUCCESS,
        payload: { datasss: userdata.data },
      });
    } else {
      dispatch({
        type: userConstants.GET_LOGIN_FAILURE,
        payload: { datasss: userdata.data },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error}`);
    dispatch({ type: userConstants.GET_LOGIN_FAILURE, payload: error.message });
  }
};

export const verifyEmail = (data) => async (dispatch) => {
  try {
    const userdata = await commonService.withOutToken(
      `/email-verification/` + data
    );
    localStorage.setItem("userData", JSON.stringify(userdata));
    if (userdata) {
      dispatch({
        type: userConstants.GET_LOGIN_SUCCESS,
        payload: { datasss: userdata.data },
      });
    } else {
      dispatch({
        type: userConstants.GET_LOGIN_FAILURE,
        payload: { datasss: userdata.data },
      });
    }
  } catch (err) {
    dispatch({ type: userConstants.GET_LOGIN_FAILURE, payload: err.message });
  }
};

export const clearLoginMsg = () => async (dispatch) => {
  try {
    dispatch({ type: userConstants.CLEAR_LOGIN_MESSAGE, payload: [] });
  } catch (err) {
    console.log(`Error while calling login api ${err}`);
  }
};

export default authenticationLogin;
