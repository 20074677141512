import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Loader from "react-js-loader";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import detailsFunction from "../../actions/detailsAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import moment from 'moment';
import $ from "jquery";
import Swal from "sweetalert2";
import eventBlockAction from "../../actions/eventBlockAction";

const ConsumerDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [newDetails, setNewDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    
    
    useEffect(() => {
        dispatch(detailsFunction(id));
    }, [dispatch,id]);
    
    // get consumer all the details with there created events.
    const { details } = useSelector((state) => state.details);
    useEffect(() => {
      if (details && details.datasss) {
        setNewDetails(details.datasss);
        setEvents(details.datasss.events);
        setLoading(false);
      }
    }, [details]);

    useEffect(() => {
        if(events && events.length > 0){
                $("#datatables").DataTable({
                    "lengthMenu": [5,10,15,25,50,100],
                    "pageLength": 5,
                    "bDestroy" : true
                });
        }
    },[events])

    const handleEventBlock = (eventId, status) => {
        Swal.fire({
          title: "Are you sure?",
          text: status === "0" ? "You want unblock this event" : "You want block this event",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: status === "0" ? "unblock!" : "block!",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(eventBlockAction({
                eventId: eventId,
                temp_down: status,
            }));
            var msg = status === "0" ? "event unblocked successfully!" : "event blocked successfully!";
            toast.success(msg);
            dispatch(detailsFunction(id));
          }
        });
    };

    return (
        <>
            {loading ? (
                <div className={"item loader-item"}>
                    <Loader
                        type="spinner-circle"
                        bgColor={"#66615B"}
                        color={"#66615B"}
                        size={100}
                    />
                </div>
            ) : (
        <div className="main-panel main-wrap">
            <NavbarLog />
            &nbsp;
            <div className="content">
                <div className="container-fluid">
                    <div className="card-header">
                        <Link to={`/admin/consumer-analytics/${id}`} className="btn btn-info btn-fill btn-wd" style={{ marginBottom: '10px' }}>View Analytics</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-5">
                            <div className="card card-user">
                                <div className="image">
                                    <img src={newDetails.user_information?.cover_photo ? newDetails.user_information?.cover_photo : "../../img/photobg.png"} alt={newDetails.user_information?.cover_photo ? "User's cover photo" : "Default cover photo"} />
                                </div>
                                <div className="card-content">
                                    <div className="author">
                                        <img className="avatar border-white" src={newDetails.profile ? newDetails.profile : "../../img/default-profile-picture.png"} alt="..." />
                                        <h3 className="card-title capitalise">{newDetails.first_name}{" "}{newDetails.last_name}</h3>
                                        <p><small>{newDetails.email}</small></p>
                                        <p><small>Login by -{" "}{newDetails.login_type ? newDetails.login_type.charAt(0).toUpperCase() + newDetails.login_type.slice(1) : ""}</small></p>
                                        <p><small>State -{" "}{newDetails.user_information?.state || ""},{" "}{newDetails.user_information?.suburb}</small></p>
                                        <p>{ newDetails.user_information?.profile_description }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h4 className="title capitalise">{newDetails.first_name} Events</h4>
                                    <p className="category">Here is the events created by {newDetails?.first_name}</p>
                                </div>
                                <div className="card-content">
                                    <div className="toolbar"></div>
                                    <div className="fresh-datatables content table-responsive table-full-width">
                                        <table id="datatables" className="table table-striped table-no-bordered table-hover" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap text-info">S.No</th>
                                                    <th className="text-nowrap text-info">Name</th>
                                                    <th className="text-nowrap text-info">Event Type</th>
                                                    <th className="text-nowrap text-info">Budget</th>
                                                    <th className="text-nowrap text-info">Location</th>
                                                    <th className="text-nowrap text-info">Description</th>
                                                    <th className="text-nowrap text-info">Vendor Response</th>
                                                    <th className="text-nowrap text-info">Start Date</th>
                                                    <th className="text-nowrap text-info">End Date</th>
                                                    <th className="text-nowrap text-info">Status</th>
                                                    <th className="text-nowrap text-info">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {events && events.length > 0 ? (
                                                    <>
                                                        {events.map((event,index) => (
                                                            <tr key={index}>
                                                                <td className="text-nowrap">{index+1}</td>
                                                                <td className="text-nowrap">{event?.event_name}</td>
                                                                <td className="text-nowrap">{event?.event_type}</td>
                                                                <td className="text-nowrap">{event?.event_budget ? `$`+(event?.event_budget) : '-'}</td>
                                                                <td>{event?.location}</td>
                                                                <td><textarea className="tbl-description form-control" rows="2"  placeholder="N/A" defaultValue={(event?.event_description) ? event?.event_description : ''}></textarea></td>
                                                                <td className="text-nowrap">{event?.vendor_response ? (event?.vendor_response) : '0'}</td>
                                                                <td className="text-nowrap">{event?.event_from_date ? moment(event.event_from_date).format('YYYY-MM-DD') : '-'}</td>
                                                                <td className="text-nowrap">{event?.event_to_date ? moment(event.event_to_date).format('YYYY-MM-DD') : '-'}</td>
                                                                <td>{event?.event_to_date && new Date(event.event_to_date) < new Date() ?
                                                                    (<h4><strong className="text-danger">Expired</strong></h4>)
                                                                    :
                                                                    (<h4><strong className="text-success">Active</strong></h4>)
                                                                }</td>
                                                                <td>
                                                                    <Link data-bs-toggle="tooltip" data-bs-placement="bottom" title="Event Block" className={event.temp_down === "0" ? "btn btn-danger" : "btn btn-info"}
                                                                        onClick={() => handleEventBlock(event?.id, event?.temp_down === "0" ? "1" : "0") }
                                                                    >
                                                                        <i className="fa fa-ban" aria-hidden="true" ></i>
                                                                    </Link>{" "}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ):(
                                                    <tr><td className="text-center" colSpan="8">No event's yet.</td></tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
            )}
        </>

    );
};
export default ConsumerDetails;
