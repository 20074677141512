import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import reviewAction from "../../actions/reviewAction";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import moment from 'moment';
import Loader from 'react-js-loader';

const Review = () => {
  const dispatch = useDispatch();
  
  const [reviewList, setReviewList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { review } = useSelector((state) => state.review);

  useEffect(() => {
    dispatch(reviewAction());
  }, [dispatch]);
  
  useEffect(() => {
    if (review && review.datasss && review.datasss.data) {
      setReviewList(review.datasss.data);
      setLoading(false)
    }
  }, [review]);

  const handleCheckedChatNotification = (checked,id) => {
    // setChatNotificationChecked(checked.target.checked)
    let tokenObj = JSON.parse(localStorage.getItem("token"));
    const headers = {
        'content-type': 'application/json',
        Authorization: `Bearer ${tokenObj.api_token}`
    }
    let obj = {}
    if (checked.target.checked) {
        obj = {
            id:id,
            status: 1
        }
        toast("Review block successfully");
    } else {
        obj = {
            id:id,
            status: 0
        }
        toast("Review Unblock successfully");
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/approve-review`, obj, { headers }).then(response => {
      toast(response.message)
      dispatch(reviewAction());
    })
}

  return (
    reviewList && reviewList.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
        {loading ? (
        <div className={"item loader-item"}>
            <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
        </div>
        ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="title">Review</h4>
                  <br />
                  <div className="card">
                    <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables content table-responsive table-full-width">
                      {reviewList.length > 0 ? ( 
                        <table id="datatables" className="table table-striped table-no-bordered table-hover" cellSpacing="0" width="100%">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Sender User</th>
                              <th>Receiver User</th>
                              <th>Review</th>
                              <th>Review Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                                {reviewList.map((user,i) => (
                                  <>
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{user.from_id[0]?.first_name}</td>
                                      <td>{user.to_id[0]?.first_name}</td>
                                      <td>{user.review}</td>
                                      <td>{user?.created_at ? moment(user.created_at).format('YYYY-MM-DD') : '-'}</td>
                                      <td>
                                        {" "}
                                        <div className="toggle-btn">
                                            <label className="switch" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Enable/Disable Review">
                                                <input type="checkbox" checked={user.status ? true : false} id="checbox" onChange={(e)=>handleCheckedChatNotification(e,user.id)} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                          </tbody>
                        </table>
                        ):(
                        <p>No data found.</p>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </>
    )
  );
}

export default Review;
