import { userblockData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const userblockFunction = (userid, status) => async (dispatch) => {
  try {
    const userblockList = await commonService.withToken(`/user-block`, {
      id: userid,
      is_blocked: status,
    });
    if (userblockList) {
      dispatch({
        type: userblockData.GET_USERBLOCK_SUCCESS,
        payload: { datasss: userblockList.data },
      });
    } else {
      dispatch({
        type: userblockData.GET_USERBLOCK_FAILURE,
        payload: { data: userblockList },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error}`);
    dispatch({
      type: userblockData.GET_USERBLOCK_FAILURE,
      payload: error.message,
    });
  }
};

export default userblockFunction;
