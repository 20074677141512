// import  axios  from "axios";
import { eventListConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const eventListAction = () => async (dispatch) =>{
    try{
      const  eventList  = await commonService.withLogin(`/event-list`)
      if(eventList){
        dispatch({type: eventListConst.GET_EVENTLIST_SUCCESS , payload: {datasss: eventList.data}})
      }
      else{
        dispatch({type: eventListConst.GET_EVENTLIST_FAILURE , payload: {data: eventList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: eventListConst.GET_EVENTLIST_FAILURE , payload: error.message})
  }
}

export default eventListAction;