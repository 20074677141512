// import  axios  from "axios";
import {paymentData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const paymentFunction = () => async (dispatch) =>{
    try{
      const  paymentList  = await commonService.withLogin(`/payment-details`)
      if(paymentList){
        dispatch({type: paymentData.GET_PAYMENT_SUCCESS , payload: {datasss: paymentList.data}})
      }
      else{
        dispatch({type: paymentData.GET_PAYMENT_FAILURE , payload: {data: paymentList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: paymentData.GET_PAYMENT_FAILURE , payload: error.message})
  }
}

export default paymentFunction;