import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import consumerFunction from "../../actions/consumerAction";
import userblockFunction from "../../actions/userblockAction";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Loader from 'react-js-loader';
import moment from 'moment';


const ConsumerList = () => {
  const [newConsumer, setNewConsumer] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const statedata = useSelector((state) => state.userblock);

  const { consumer } = useSelector((state) => state.consumer);

  useEffect(() => {
    dispatch(consumerFunction());
  }, [dispatch]);

  useEffect(()=>{
    if(statedata){
    dispatch(consumerFunction());
    }
  },[statedata])
  
  const handleUserAction = (userid, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: status == 0 ? "You want unblock user" : "You want block user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: status == 0 ? "unblock!" : "block!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(userblockFunction(userid, status));
        var msg = status == 0 ? "user unblocked successfully!" : "user blocked successfully!";
        toast.success(msg);
      }
    });
  };

  useEffect(() => {
    if (consumer && consumer.datasss && consumer.datasss.data) {
      setNewConsumer(consumer.datasss.data);
      setLoading(false)
    }
  }, [consumer]);

  return (
    newConsumer && newConsumer.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
      {loading ? (
        <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
        </div>
      ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <ToastContainer />
                  <div className="card">
                    <div className="header">
                      <h4 className="title">Consumers List</h4>
                    </div>
                    <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables content table-responsive table-full-width">
                      {newConsumer.length > 0 ? ( <table
                          id="datatables"
                          className="table table-striped table-no-bordered table-hover"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Full Name</th>
                              <th>Email</th>
                              <th>Chat Threads</th>
                              <th>Created Date</th>
                              <th>Last Login</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {newConsumer.length > 0 && (
                              newConsumer.map((user,index) => (
                                <tr key={user.id}>
                                  <td>{index+1}</td>
                                  <td>{user.first_name +' '+ user.last_name}</td>
                                  <td>{user.email ? user.email : 'N/A'}</td>
                                  <td>{user?.user_chat_count ? user?.user_chat_count : '0'}</td>
                                  <td>{user?.created_at ? moment(user.created_at).format('YYYY-MM-DD') : '-'}</td>
                                  <td>{user?.last_login ? moment(user.last_login).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                  <td>
                                      <>
                                        <Link data-bs-toggle="tooltip" data-bs-placement="bottom" title="User Block" to="#" className={user.is_blocked == 0 ? "btn btn-danger" : "btn btn-info"}
                                          onClick={() =>
                                            handleUserAction(user.id, user.is_blocked == 0 ? "1" : "0")
                                          }
                                        >
                                          <i className="fa fa-ban" aria-hidden="true" ></i>
                                        </Link>{" "}
                                        <Link to={`/admin/consumer-analytics/${user.id}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Consumer Analytics" className="btn btn-primary">
                                          <i className="fa fa-area-chart" aria-hidden="true"></i>
                                        </Link>{" "}
                                        <Link data-bs-toggle="tooltip" data-bs-placement="bottom" title="User Info"
                                          to={`/admin/consumer-details/${user.id}`} className="btn btn-success">
                                          View
                                        </Link>
                                      </>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        ):(
                        <p>No data found.</p>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
      </>
    )
  );
};

export default ConsumerList;
