import { commonService } from "../services/common.service";
import { UseLoginLogsConst } from "../constants/user.constants";

 const userLoginLogsAction = (data) => async (dispatch) =>{
    try{
      const  UserLoginLogs  = await commonService.withToken('/user-loginlogs-data', data);
      if(UserLoginLogs){
        dispatch({type: UseLoginLogsConst.GET_USERLOGINLOGS_SUCCESS , payload: {userLogs : UserLoginLogs.data}})
      }
      else{
        dispatch({type: UseLoginLogsConst.GET_USERLOGINLOGS_FAILURE , payload: {data: UserLoginLogs.data}})
      }
  }catch(error){
      console.log(`Error while calling login logs api ${error}`)
      dispatch({ type: UseLoginLogsConst.GET_USERLOGINLOGS_FAILURE , payload: error.message})
  }
};

export default userLoginLogsAction;