export const userConstants = {
  GET_LOGIN_REQUEST: "GET_LOGIN_REQUEST",
  GET_LOGIN_SUCCESS: "GET_LOGIN_SUCCESS",
  GET_LOGIN_FAILURE: "GET_LOGIN_FAILURE",
  CLEAR_LOGIN_MESSAGE: "CLEAR_LOGIN_MESSAGE",
};
export const forgetPassword = {
  GET_FORGET_SUCCESS: "GET_FORGET_SUCCESS",
  GET_FORGET_FAILURE: "GET_FORGET_FAILURE",
};

export const resetPassword = {
  GET_RESET_REQUEST: "GET_RESET_REQUEST",
  GET_RESET_SUCCESS: "GET_RESET_SUCCESS",
  GET_RESET_FAILURE: "GET_RESET_FAILURE",
};

export const dashboardCount = {
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_FAILURE: "GET_DASHBOARD_FAILURE",
};

export const consumerData = {
  GET_CONSUMER_SUCCESS: "GET_CONSUMER_SUCCESS",
  GET_CONSUMER_FAILURE: "GET_CONSUMER_FAILURE",
};

export const eventListConst = {
  GET_EVENTLIST_SUCCESS: "GET_EVENTLIST_SUCCESS",
  GET_EVENTLIST_FAILURE: "GET_EVENTLIST_FAILURE",
};

export const eventDetailConst = {
  GET_EVENTDETAIL_SUCCESS: "GET_EVENTDETAIL_SUCCESS",
  GET_EVENTDETAIL_FAILURE: "GET_EVENTDETAIL_FAILURE",
};

export const vendorData = {
  GET_VENDOR_SUCCESS: "GET_VENDOR_SUCCESS",
  GET_VENDOR_FAILURE: "GET_VENDOR_FAILURE",
};

export const chatLogsConst = {
  GET_CHATLOGS_SUCCESS: "GET_CHATLOGS_SUCCESS",
  GET_CHATLOGS_FAILURE: "GET_CHATLOGS_FAILURE",
};

export const paymentData = {
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAILURE: "GET_PAYMENT_FAILURE",
};

export const reportData = {
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",
};

export const feedbackData = {
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
  GET_FEEDBACK_FAILURE: "GET_FEEDBACK_FAILURE",
};

export const reviewData = {
  GET_REVIEW_SUCCESS: 'GET_REVIEW_SUCCESS',
  GET_REVIEW_FAILURE: 'GET_REVIEW_FAILURE'
}

export const subscriptionData = {
  GET_SUBSCRIPTION_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
  GET_SUBSCRIPTION_FAILURE: "GET_SUBSCRIPTION_FAILURE",
};

export const userblockData = {
  GET_USERBLOCK_SUCCESS: "GET_USERBLOCK_SUCCESS",
  GET_USERBLOCK_FAILURE: "GET_USERBLOCK_FAILURE",
};

export const logoutData = {
  GET_LOGOUT_SUCCESS: "GET_LOGOUT_SUCCESS",
  GET_LOGOUT_FAILURE: "GET_LOGOUT_FAILURE",
};

export const detailsData = {
  GET_DETAILS_SUCCESS: "GET_DETAILS_SUCCESS",
  GET_DETAILS_FAILURE: "GET_DETAILS_FAILURE",
};

export const VendorAnalyticsConst = {
  GET_VENDORANALYTICS_SUCCESS: "GET_VENDORANALYTICS_SUCCESS",
  GET_VENDORANALYTICS_FAILURE: "GET_VENDORANALYTICS_FAILURE",
};

export const ConsumerAnalyticsConst = {
  GET_CONSUMERANALYTICS_SUCCESS: "GET_CONSUMERANALYTICS_SUCCESS",
  GET_CONSUMERANALYTICS_FAILURE: "GET_CONSUMERANALYTICS_FAILURE",
};

export const UseLoginLogsConst = {
  GET_USERLOGINLOGS_SUCCESS: "GET_USERLOGINLOGS_SUCCESS",
  GET_USERLOGINLOGS_FAILURE: "GET_USERLOGINLOGS_FAILURE",
};

export const signupConstants = {
  GET_SIGNUP_SUCCESS: "GET_SIGNUP_SUCCESS",
  GET_SIGNUP_FAILURE: "GET_SIGNUP_FAILURE",
};

export const extraConstants = {
  GET_EXTRA_SUCCESS: "GET_EXTRA_SUCCESS",
  GET_EXTRA_FAILURE: "GET_EXTRA_FAILURE",
};

export const googleConstants = {
  GET_GOOGLE_SUCCESS: "GET_GOOGLE_SUCCESS",
  GET_GOOGLE_FAILURE: "GET_GOOGLE_FAILURE",
};

export const userReviewDeleteData = {
  GET_USERREVIEWDELETE_SUCCESS: 'GET_USERREVIEWDELETE_SUCCESS',
  GET_USERREVIEWDELETE_FAILURE: 'GET_USERREVIEWDELETE_FAILURE'
}

export const postInactiveData = {
  GET_POSTINACTIVE_SUCCESS: 'GET_POSTINACTIVE_SUCCESS',
  GET_POSTINACTIVE_FAILURE: 'GET_POSTINACTIVE_FAILURE'
}

export const reasonReportData = {
  GET_REASONREPORT_SUCCESS: 'GET_REASONREPORT_SUCCESS',
  GET_REASONREPORT_FAILURE: 'GET_REASONREPORT_FAILURE'
}


export const varificationConstants = {
  GET_VARIFICATION_SUCCESS: "GET_VARIFICATION_SUCCESS",
  GET_VARIFICATION_FAILURE: "GET_VARIFICATION_FAILURE",
};

export const editUserConst = {
  GET_EDITUSER_SUCCESS: "GET_EDITUSER_SUCCESS",
  GET_EDITUSER_FAILURE: "GET_EDITUSER_FAILURE",
};

export const clearEditUserConst = {
  CLEAR_EDITUSER_MESSAGE: 'CLEAR_EDITUSER_MESSAGE'
}

export const eventTypeConst = {
  GET_EVENTTYPE_SUCCESS: "GET_EVENTTYPE_SUCCESS",
  GET_EVENTTYPE_FAILURE: "GET_EVENTTYPE_FAILURE",
};

export const eventBlockConst = {
  GET_EVENTBLOCK_SUCCESS: "GET_EVENTBLOCK_SUCCESS",
  GET_EVENTBLOCK_FAILURE: "GET_EVENTBLOCK_FAILURE",
};

export const RegionConst = {
  GET_REGION_SUCCESS: 'GET_REGION_SUCCESS',
  GET_REGION_FAILURE: 'GET_REGION_FAILURE'
}

export const categoryData = {
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_FAILURE: 'GET_CATEGORY_FAILURE'
}