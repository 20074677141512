import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import detailsFunction from "../../actions/detailsAction";
import editUserAction from "../../actions/editUser";
import clearEditUserMsg  from "../../actions/editUser";
import eventTypeAction from "../../actions/eventTypeAction";
import regionAction from "../../actions/regionAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import moment from 'moment';
import AWS from "aws-sdk";
import Select from "react-select";
import Loader from 'react-js-loader';
import OwlCarousel from "react-owl-carousel";
import categoryAction from "../../actions/categoryAction";
import axios from "axios";

const VendorDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { details } = useSelector((state) => state.details);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_YOUR_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_YOUR_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_YOUR_REGION,
  });

  const [error, setError] = useState(false);
  const [newDetails, setNewDetails] = useState({});
  const [vendorData, setVendorData] = useState("");
  const [eventTypeList, setEventType] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [cover_photo, setCoverPhoto] = useState("");


  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);

  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [vendor_photos, setVendorPhotos] = useState([]);
  const [delete_photos, setDeletePhotos] = useState([]);
  const [promoPhotoCount, setPromoPhotoCount] = useState(0);
  
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [promoVideoCount, setPromoVideoCount] = useState(0);

  const [portraitReel, setPortraitReel] = useState("");
  const [landscapeReel, setLandscapeReel] = useState("");
  const [reels, setReels] = useState([]);
  const [vendor_reels, setVendorReels] = useState([]);
  const [deletedReels, setDeletedReels] = useState([]);
  
  const [videoProgress, setVideoProgress] = useState(0);
  const [landscapeReelProgress, setLandscapeReelProgress] = useState(0);
  const [portraitReelProgress, setPortraitReelProgress] = useState(0);
  
  // const [loadingFile, setLoadingFile] = useState(false);
  const [loadingVideoFile, setLoadingVideoFile] = useState(false);
  const [loadingPotrateReelFile, setLoadingPotrateReelFile] = useState(false);
  const [loadingLandReelFile, setLoadingLandReelFile] = useState(false);
  const [uniqueBusinessNameError, setUniqueBusinessNameError] = useState(false);


  const initAll = () => {
    setVendorData({});
    setRegionList([]);
    setCategoryList([]);
    setVideos([]);
    setPortraitReel("");
    setLandscapeReel("");
    setReels([]);
    setVendorReels([]);
    setSelectedFile([]);
  };

  useEffect(() => {
    initAll();
    dispatch(detailsFunction(id));
    dispatch(eventTypeAction());
    dispatch(categoryAction())
    dispatch(regionAction());
  }, [dispatch,id]);

  // get all the event type for vendor
  const eventType = useSelector((state) => state.eventType.eventType);
  useEffect(() => {
      if (eventType && eventType.datasss && eventType.datasss.status === true) {
          setEventType(eventType.datasss.data);
      }
  }, [eventType]);

  // get all the regions
  const regionsDtl = useSelector((state) => state?.region?.region);
  useEffect(() => {
    if (regionsDtl && regionsDtl.datasss && regionsDtl.datasss.status === true ) {
      setRegionList(regionsDtl.datasss.data);
    }
  }, [regionsDtl]);
  
  // get all the regions
  const categoriesDtl = useSelector((state) => state?.category?.category);
  useEffect(() => {
    if (categoriesDtl && categoriesDtl.datasss && categoriesDtl.datasss.status === true ) {
      setCategoryList(categoriesDtl.datasss.data);
    }
  }, [categoriesDtl]);

  useEffect(() => {
    let tempVendorData = { ...vendorData };
    if (details && details.datasss) {
        tempVendorData.id                 = details.datasss.id;
        tempVendorData.email              = details.datasss.email;
        tempVendorData.first_name         = details.datasss.first_name || "";
        tempVendorData.last_name          = details.datasss.last_name || "";
        tempVendorData.business_name      = details.datasss.business_name;
        tempVendorData.vendor_description = details.datasss.user_information.profile_description;
        tempVendorData.postcode           = details.datasss.user_information.postcode;
        tempVendorData.starting_price     = details.datasss.user_information.starting_price;
        tempVendorData.vendor_category    = details.datasss.vendor_category;
        tempVendorData.vendor_regions     = details.datasss.vendor_regions;
        tempVendorData.reel_caption       = details.datasss.user_information.reel_caption;
        setNewDetails(details.datasss);
        setVendorPhotos(details?.datasss?.vendor_photos);
        const promoPhoto = details?.datasss?.vendor_photos.filter(
            (x) => x.type === "image"
        );
        setPromoPhotoCount(promoPhoto.length);

        setVendorReels(details?.datasss?.reels);
        functionToSetReels();
    }
    setVendorData(tempVendorData);
    setLoading(false);
  }, [details,vendor_reels]);

  const functionToSetReels = () => {
      if ( details.datasss && details?.datasss?.reels?.length > 0 ) {
        let landscapeIndex = vendor_reels?.findIndex(
            (reel) => reel?.type === "landscape"
        );
        let portraitIndex = vendor_reels?.findIndex(
            (reel) => reel?.type === "portrait"
        );
        let landscapeData = [];
        if (landscapeIndex > -1) {
          landscapeData.push({
            id: vendor_reels[landscapeIndex]?.id,
            file: vendor_reels[landscapeIndex]?.reel_add,
            type: vendor_reels[landscapeIndex]?.type,
          });
        }
        setLandscapeReel(landscapeData);
        let portraitData = [];
        if (portraitIndex > -1) {
            portraitData.push({
                id: vendor_reels[portraitIndex]?.id,
                file: vendor_reels[portraitIndex]?.reel_add,
                type: vendor_reels[portraitIndex]?.type,
            });
        }
        setPortraitReel(portraitData);
        setReels([...landscapeData, ...portraitData]);
    } else {
        setPortraitReel("");
        setLandscapeReel("");
        setReels([]);
    }
  }

  const handleChange = (e) => {
    let tempVendorData = { ...vendorData };
    tempVendorData[e.target.name] = e.target.value;
    if (tempVendorData[e.target.name].length <= 4000) {
      setVendorData(tempVendorData);
    }
  };

  const handleChangeBusinessName = (e) => {
    const businessEventType = e.type;
    let tempVendorData = { ...vendorData };
    tempVendorData[e.target.name] = e.target.value;
    setVendorData(tempVendorData);
    if(businessEventType == 'blur')
    {
        checkBusinessName(tempVendorData[e.target.name]);
    }
  };
  const checkBusinessName = async (businessName) => {
    setUniqueBusinessNameError(false);
    try {
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {
            business_name: businessName,
        }
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/check-business-name`, obj, { headers });
        setUniqueBusinessNameError(!response.data.data.is_unique);
    } catch (error) {
        console.error('Error checking business name uniqueness:', error);
    }
  };

  useEffect(() => {
      const promoVideo = vendor_photos.filter((item) => item.type === "video");
    if (videos.length > 0) {
        setPromoVideoCount(videos.length);
    } else {
        setPromoVideoCount(promoVideo.length);
    }
  }, [vendor_photos]);

  useEffect(() => {
    if(details?.datasss && details?.datasss?.user_information?.state){
      let obj = {
          label: details.datasss.user_information.state,
          value: details.datasss.user_information.state,
      };
      setSelectedState(obj);
    }
    //get vendor selected event type 
    if (newDetails && newDetails.vendor_category) {
      let temp = [];
      newDetails.vendor_category.map((data) => {
          let obj = {
              label: data.category.category_name,
              value: data.category.id,
          };
          temp.push(obj);
      });
      setSelectedCategory(temp);
    }
    //get vendor selected event type 
    if (newDetails && newDetails.vendor_event_type) {
        let temp = [];
        newDetails.vendor_event_type.map((data) => {
            let obj = {
                label: data.event_type.event_type,
                value: data.event_type.id,
            };
            temp.push(obj);
        });
        setSelectedEventType(temp);
    }
  //get vendor selected region's type 
    if (newDetails && newDetails.vendor_regions) {
      let temp = [];
      newDetails.vendor_regions.map((data) => {
          let obj = {
              label: data.name,
              value: data.region_id,
          };
          temp.push(obj);
      });
      setSelectedRegion(temp);
    }
  }, [newDetails]);
  
  const handleChangeState = (e) => {
    setSelectedState(e);
  };
  const handleChangeCategory = (e) => {
    setSelectedCategory(e ? [e] : []);
  };
  const handleChangeEventType = (e) => {
    setSelectedEventType(e);
  };
  const handleChangeRegion = (e) => {
    setSelectedRegion(e);
  };

    // selet and set vendor profile photo and convert into base64
  const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
              resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
              reject(error);
          };
      });
  };

  const handelProfilePhoto = async (e) => {
      // for vendor profile picture
      let file = e.target.files[0];
      let base64 = await convertToBase64(file);
      if (base64) {
          let base64Split = base64.split(";");
          let splitExtension = base64Split[0].split("/");
          if (
              splitExtension[1].toLowerCase() === "png" ||
              splitExtension[1].toLowerCase() === "jpg" ||
              splitExtension[1].toLowerCase() === "jpeg"
          ) {
              setProfilePicture(base64);
          } else {
              toast.warn("The accepted file formats are: jpeg, png, or jpg.");
          }
      }
  };

  const handelCoverPhoto = async (e) => {
    // for vendor cover photo
    let file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
        const img = new Image();
        img.onload = async () => {
            const width = img.width;
            const height = img.height;

            if (width >= 900 && height >= 400) {
                let base64 = await convertToBase64(file);
                if (base64) {
                    let base64Split = base64.split(";");
                    let splitExtension = base64Split[0].split("/");
                    if (
                        splitExtension[1].toLowerCase() === "png" ||
                        splitExtension[1].toLowerCase() === "jpg" ||
                        splitExtension[1].toLowerCase() === "jpeg"
                    ) {
                        setCoverPhoto(base64);
                    } else {
                        toast.warn("The accepted file formats are: jpeg, png, or jpg.");
                    }
                }
            } else {
                toast.warn("Please upload an image with dimensions 900x400.");
            }
        };
        img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  // get video duration
  const getVideoDuration = async (file) => {
      return new Promise((resolve, reject) => {
          const video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = function () {
              resolve(video.duration);
          };
          video.onerror = function () {
              reject("Error occurred while loading the video.");
          };
          video.src = URL.createObjectURL(file);
      });
  };
  
  const handleVendorPhotos = async (e) => {
      // for vendor multi photos
      let files = Array.prototype.slice.call(e.target.files);
      const newArr = vendor_photos.filter((x) => {
          return x.type === "image";
      });
      let fileCount = newArr.length + files.length + selectedPhotos.length;

      if (fileCount <= 10) {
          const fileArr = [...selectedPhotos];
          const tempUrl = [...photos];

          for (let file of files) {
              let type = file.type;
              let splitExtension = type.split("/");
              if (
                  splitExtension[1].toLowerCase() === "png" ||
                  splitExtension[1].toLowerCase() === "jpg" ||
                  splitExtension[1].toLowerCase() === "jpeg"
              ) {
                  let obj = {
                      type: "image",
                      file: file,
                      size: (file?.size) ? file.size : 0, // bytes
                  };
                  fileArr.push(obj);
                  setSelectedPhotos(fileArr);
                  let url = URL.createObjectURL(obj.file);

                  tempUrl.push(url);
                  setPhotos(tempUrl);
                  setPromoPhotoCount(tempUrl.length + newArr.length);
              } else {
                  toast.warn("The accepted file formats are: jpeg, png, or jpg.");
              }
          }
      } else {
          toast.warn("You can't submit more than 10 photos.");
      }
  };

  const deleteImage = (photo, index) => {
      // delete image
      let temp = [...vendor_photos];
      let deleteImg = [...delete_photos];
      let tempSelect = [...selectedPhotos];
      temp.splice(index, 1);
      tempSelect.splice(index, 1);
      setVendorPhotos(temp);
      setSelectedPhotos(tempSelect);
      setPromoPhotoCount(promoPhotoCount - 1);
      if (photo.id) {
          deleteImg.push(photo.id);
          setDeletePhotos(deleteImg);
      }
  };

  const deleteCurrentImage = (index) => {
      // remove selected image
      let temp = [...selectedPhotos];
      temp.splice(index, 1);
      setSelectedPhotos(temp);
      let newArr = photos.filter((id, idx) => idx !== index);
      setPhotos(newArr);
      setPromoPhotoCount(promoPhotoCount - 1);
  };

  const handleVendorVideos = async (e) => {
      // for vendor videos
      let files = Array.prototype.slice.call(e.target.files);
      const newArr = vendor_photos.filter((x) => {
          return x.type === "video";
      });
      let fileCount = newArr.length + videos.length;
      const videoArr = [...selectedVideos];
      const tempUrl = [...videos];
      for (let video of files) {
          const file = video;
          // let type = video.type;
          let extension = file.name.split(".").pop().toLowerCase();
          // check video extension
          if (extension === "mp4" || extension === "mov") {
              // check video count
              if (fileCount >= 2) {
                  toast.warn("You can't submit more than 2 videos.");
              } else {
                  // setLoadingFile(true);
                  const videoI = document.createElement("video");
                  const reader = new FileReader();
                  if (videoI) {
                      reader.addEventListener("load", async function () {
                          videoI.src = reader.result;
                          const reelDuration = await getVideoDuration(file);
                          // videoI.addEventListener('loadedmetadata', async function () {
                          //check videos duration
                          if (reelDuration <= 300) {
                              let obj = {
                                  type: "video",
                                  file: file,
                                  size: (file?.size) ? file.size : 0, // bytes
                              };
                              videoArr.push(obj);
                              setSelectedVideos(videoArr);
                              let url = URL.createObjectURL(obj.file);

                              tempUrl.push({ url: url });
                              setVideos(tempUrl);
                              setPromoVideoCount(promoVideoCount + 1);
                              // setLoadingFile(false);
                          } else {
                              toast.warn("Video duration should be a maximum of 5 minutes.");
                              // setLoadingFile(false);
                          }
                          // });
                      });
                  }
                  // for checking uploadding persentage
                  reader.addEventListener("progress", function (event) {
                      if (event.lengthComputable) {
                          setLoadingVideoFile(true);
                          const percentLoaded = Math.round((event.loaded / event.total) * 100);
                          setVideoProgress(`${percentLoaded}% complete`);
                          if (percentLoaded === 100) {
                              setTimeout(() => {     
                                  setLoadingVideoFile(false);
                              }, 1000);
                          }
                      }
                  });
                  reader.readAsDataURL(file);
              }
          } else {
              toast.warn("The accepted video formats are: mp4.");
          }
      }
  };

  const deleteVideo = (photo, index) => {
      // delete image
      let temp = [...vendor_photos];
      if (temp) {
          temp.splice(index, 1);
      }
      let deleteImg = [...delete_photos];
      let tempSelect = [...selectedVideos];
      tempSelect.splice(index, 1);
      setVendorPhotos(temp);
      setSelectedVideos(tempSelect);
      if (photo.id) {
          deleteImg.push(photo.id);
          setDeletePhotos(deleteImg);
      }
  };

  const deleteCurrentVideo = (url, index) => {
      const tempSelectedVideos = [...selectedVideos];

      tempSelectedVideos.splice(index, 1);
      setSelectedVideos(tempSelectedVideos);

      const videoIndexToDelete = videos.findIndex((video) => video.url === url);

      if (videoIndexToDelete !== -1) {
          const tempVideos = [...videos];
          tempVideos.splice(videoIndexToDelete, 1);
          setVideos(tempVideos);
      }
      setPromoVideoCount(promoVideoCount - 1);
  };

  useEffect(() => {
      setSelectedFile([...selectedPhotos, ...selectedVideos]);
  }, [selectedVideos, selectedPhotos]);
  

  // function to update reel and get there duration or extension
  const handleReels = async (e, type) => {
    let reel = e.target.files[0];
    let reelExtension = reel.type;
    let splitExtension = reelExtension.split("/");
    let extension = reel.name.split(".").pop().toLowerCase();
    // check video extension
    if (extension === "mp4" || extension === "mov") {
        const videoI = document.createElement("video");
        const reader = new FileReader();
        if (videoI) {
            reader.addEventListener("load", async function () {
                videoI.src = reader.result;
                const reelDuration = await getVideoDuration(reel);
                //   videoI.addEventListener('durationchange', function () {
                if (reelDuration <= 242) {
                    let reelsArr = [...reels];
                    let obj = {
                        type: type,
                        file: reel,
                        size: (reel?.size) ? reel.size : 0,
                    };
                    let url = URL.createObjectURL(obj.file);
                    let newReel = {
                        type: type,
                        file: url,
                    };

                    if (obj.type === "landscape") {
                        setLandscapeReel([newReel]);
                    } else {
                        setPortraitReel([newReel]);
                    }

                    reelsArr.push(obj);
                    setReels(reelsArr);
                } else {
                    toast.warn("Reel duration should be a maximum of 4 minutes.");
                }
                //   });

                videoI.addEventListener("error", function (e) {
                    console.log("Error occurred:", e);
                });
            });
            // for checking uploadding persentage
            reader.addEventListener("progress", function (event) {
                if (event.lengthComputable) {

                  const percentLoaded = Math.round((event.loaded / event.total) * 100);
                  if (type === "landscape") {
                    setLoadingLandReelFile(true);
                    setLandscapeReelProgress(`${percentLoaded}% complete`);
                  } else {
                    setLoadingPotrateReelFile(true);
                    setPortraitReelProgress(`${percentLoaded}% complete`);
                  }
        
                  if (percentLoaded === 100) {
                    // Loading is complete, set loading to false
                    if (type === "landscape") {
                        setTimeout(() => {     
                            setLoadingLandReelFile(false);
                        }, 1000);
                    } else {
                        setTimeout(() => {     
                            setLoadingPotrateReelFile(false);
                        }, 1000);
                    }
                  }
                }
            });
            reader.readAsDataURL(reel);
        }
    } else {
        toast.warn("The reel format must be mp4 or mov.");
    }
  };

  // function to remove or delete reel
  const deleteCurrentReel = (type, reel) => {
      let temp = {};
      if (type === "landscape") {
          setLandscapeReel("");
          let tempArr = [...reels];
          let tempIndex = tempArr.findIndex((item) => {
              return item.type === type;
          });
          tempArr.splice(tempIndex, 1);
          setReels(tempArr);
          temp = { ...reel, type: type };
          temp?.id && setDeletedReels([...deletedReels, temp]);
      }
      if (type === "portrait") {
          setPortraitReel("");
          let tempArr = [...reels];
          let tempIndex = tempArr.findIndex((item) => {
              return item.type === type;
          });
          tempArr.splice(tempIndex, 1);
          setReels(tempArr);
          temp = { ...reel, type: type };
          temp?.id && setDeletedReels([...deletedReels, temp]);
      }
  };

  // function to update vendor details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    if (!vendorData.business_name || !vendorData.first_name || !vendorData.last_name || selectedCategory.length <= 0 || selectedEventType.length <= 0  || 
        selectedRegion.length <= 0 || !selectedState.value || !vendorData.postcode || !vendorData.vendor_description || reels.length <= 0 || uniqueBusinessNameError) {
        setError(true);
        setLoadingForm(false);
        return false;
    }else{
        let tempFile = [];
        let tempReel = [];
        if (selectedFile) {
          for (const file of selectedFile) {
              const ext = file.file.name.split(".").pop(); // Get the original extension of the file
              const uniqueString = `${Math.random()
                  .toString(36)
                  .substring(2, 14)}${Math.random().toString(36).substring(2, 14)}`;
              const filename = `v_${uniqueString}.${ext}`;
              const params = {
                  Bucket: process.env.REACT_APP_S3_BUCKET,

                  Key: `inputs/${filename}`,
                  Body: file.file,
                  ContentType: file.type,
              };
              try {
                  const data = await s3.upload(params).promise();
                  //videoArr.push(data.Location);
                  let obj = {
                      type: file.type,
                      file: data.Location,
                      size: file.size,
                  };
                  tempFile.push(obj);
              } catch (err) {
                  toast.warn('Something went wrong. Please try again');
                  console.log("Error uploading file:", err);
                  setLoadingForm(false);
                  return;
              }
          }
        }
        if (reels) {
          for (const file of reels) {
              if (!file.id) {
                  const ext = file.file.name.split(".").pop(); // Get the original extension of the file
                  const uniqueString = `${Math.random()
                      .toString(36)
                      .substring(2, 14)}${Math.random()
                          .toString(36)
                          .substring(2, 14)}`;
                  const filename = `r_${uniqueString}.${ext}`;
                  const params = {
                      Bucket: process.env.REACT_APP_S3_BUCKET,
                      Key: `inputs/${filename}`,
                      Body: file.file,
                      ContentType: file.file.type,
                  };

                  try {
                      const data = await s3.upload(params).promise();
                      let obj = {
                          type: file.type,
                          file: data.Location,
                          size: file.size
                      };
                      tempReel.push(obj);
                  } catch (err) {
                      toast.warn('Something went wrong. Please try again');
                      console.log("Error uploading file:", err);
                      setLoadingForm(false);
                      return;
                  }
              }
          }
        }
        dispatch(editUserAction({ 
          user_id : id,
          business_name: vendorData.business_name, 
          first_name: vendorData.first_name,
          last_name: vendorData.last_name,
          profile_picture: profilePicture,
          cover_photo: cover_photo,
          vendor_category: selectedCategory,
          vendor_event_type: selectedEventType,
          vendor_regions: selectedRegion, 
          starting_price: vendorData.starting_price,
          state: selectedState.value, 
          postcode: vendorData.postcode,
          profile_description: vendorData.vendor_description,
          reel_caption: vendorData.reel_caption,

          vendor_file: tempFile,
          deleteFile: delete_photos,

          reels: tempReel,
          deleted_reels: deletedReels,
        }))
        setTimeout(() => {
          dispatch(detailsFunction(id));
        }, 2000);
    }
  }

  // select data from edit user reducer and set toaster here
  const editUserDtl = useSelector((state) => state.editUser.editUser)
  useEffect(() => {
      if (editUserDtl && editUserDtl?.datasss && editUserDtl?.datasss?.status === true) {
          toast.success(editUserDtl?.datasss?.message)
          dispatch(clearEditUserMsg());
          navigate('/admin/vendor');
          setLoadingForm(false);
      }
      if (editUserDtl && editUserDtl?.datasss && editUserDtl?.datasss?.status === false) {
          toast.error(editUserDtl?.datasss?.message)
          dispatch(clearEditUserMsg());
          setLoadingForm(false);
      }
  }, [editUserDtl]);


  return (
    <>
      {loading ? (
        <div className={"item loader-item"}>
            <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
        </div>
      ) : (
        <div className="main-panel main-wrap">
          <NavbarLog />
          {loadingForm && (
            <div className={"item loader-item admin-item-loader"}>
                <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
                <p style={{textAlign:"center"}}>
                    Please wait... <br/>
                    Vendor profile is being published. <br/>
                </p>
            </div>
          )}
          <div className="content admin-content">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 col-md-5">
                    <div className="card card-user">
                      <div className="avatar-upload-sec profile-avtar-sec">
                          <span className="profile-avtar-upload">
                              <img
                                  className="cover-photo-section"
                                  src={ cover_photo ? cover_photo : newDetails.user_information?.cover_photo ? newDetails.user_information?.cover_photo : "/img/default-cover.png"}
                                  alt=""
                              />
                              <div className="upload-icon">
                                  <img src="/img/Download.svg" alt="" />
                                  <span>Upload cover</span>
                              </div>
                          </span>
                          <input
                              type="file"
                              accept=".jpeg,.png,.jpg,.gif,.svg"
                              name="cover_photo"
                              onChange={(e) => handelCoverPhoto(e)}
                          />
                      </div>
                      <div className="card-content">
                        <div className="author">
                          <div className="avatar-upload-sec">
                              <span>
                                  <img className="uploaded-img avatar border-white" 
                                    src={profilePicture ? profilePicture : newDetails?.profile ? newDetails?.profile : "/img/default-profile-picture.png"
                                  } alt=""/>
                                  <img src="/img/Download.svg" className="download-icon" alt="" />
                              </span>
                            <input type="file" name="profilePicture" accept=".jpeg,.png,.jpg,.gif,.svg"
                                onChange={(e) => handelProfilePhoto(e)}
                            />
                          </div>
                          <h3 className="card-title">
                            {newDetails.first_name}{" "}
                            {newDetails.last_name}<br/>
                            <small>@{newDetails.business_name}</small>
                          </h3>
                          <p> "{newDetails.email}" </p>
                          <p>
                            <small>
                              Login By - <b>{newDetails.login_type ? newDetails.login_type.charAt(0).toUpperCase() + newDetails.login_type.slice(1): ""}</b>
                            </small>
                          </p>
                        </div>
                      </div>

                      <hr/>
                      <div className="text-center">
                        <div className="row">
                          <div className="col-md-6">
                            {(newDetails.step === 0) ? 
                                (<h4>Is Live <i className="fa fa-circle text-success"></i></h4>)
                              :  
                                (<h4>Not Live <i className="fa fa-circle text-danger"></i></h4>)
                            }
                          </div>
                          <div className="col-md-6">
                            {newDetails.vendor_subscription && newDetails.vendor_subscription !== null ? (
                                <h4><i className="text-success">Subscribed</i></h4>
                            ) : (
                                <h4><i className="text-danger">Not Subscribe</i></h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-content">
                        <h4 className="common-label"> USER RATING </h4>
                        <hr />
                        <div>
                          {newDetails.user_review &&
                            newDetails.user_review.length > 0 &&
                            newDetails.user_review.map((data) => (
                              <div className="row" key={data.id}>
                                <div className="col-md-4">
                                  <h5>
                                    {data?.review}
                                    <br />
                                    <small>Review</small>
                                  </h5>
                                </div>
                                <div className="col-md-4">
                                  <h5 className="user-details">
                                    {data?.reply}
                                    <br />
                                    <small>Reply description</small>
                                  </h5>
                                </div>
                                <div className="col-md-2">
                                  <h5>
                                    {data?.reply_date ? moment(data?.reply_date).format('DD-MM-YYYY') : '-'}
                                    <br />
                                    <small>Date</small>
                                  </h5>
                                </div>
                                <div className="col-md-2">
                                  <h5>
                                    {data?.rating}<i className="fa fa-star" aria-hidden="true"></i>
                                    <br />
                                    <small>Rating</small>
                                  </h5>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7">
                    <div className="card">
                      <div className="header">
                        <h4 className="title">Edit Profile</h4>
                      </div>
                      <div className="content">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Businnes Name<span className="text-danger">*</span></label>
                              <input type="text" className="form-control border-input" placeholder="Business name / Company name" name="business_name"
                                onChange={handleChangeBusinessName}
                                onBlur={handleChangeBusinessName}
                                value={vendorData.business_name || ''}
                              />
                              {error && !vendorData.business_name && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                              {vendorData.business_name && uniqueBusinessNameError && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Business Name already exists
                                  </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Email address</label>
                              <input type="email" className="form-control border-input" placeholder="Email" name="email"
                                readOnly value={vendorData.email || ''} 
                              />
                              {error && !vendorData.email && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>First Name<span className="text-danger">*</span></label>
                              <input type="text" className="form-control border-input" placeholder="First Name" name="first_name"
                              onChange={handleChange}
                              value={vendorData.first_name || ''}
                              />
                              {error && !vendorData.first_name && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Last Name<span className="text-danger">*</span></label>
                              <input type="text" className="form-control border-input" placeholder="Last Name" name="last_name"
                                onChange={handleChange}
                                value={vendorData.last_name || ''} 
                              />
                              {error && !vendorData.last_name && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Categories<span className="text-danger">*</span></label>
                              {/* <select className="form-select form-control" aria-label="Default select example" readOnly>
                                  {vendorData.vendor_category && vendorData.vendor_category.map((list) => (
                                      <option key={list.id} value={list.id}>{list?.category?.category_name}</option>
                                  ))}
                              </select> */}
                              <Select
                                  options={categoryList.map((data, index) => {
                                      return {
                                          label: data.category_name,
                                          value: data.id,
                                      };
                                  })}
                                  value={selectedCategory}
                                  onChange={handleChangeCategory}
                                  // isMulti
                              />
                              {error && selectedCategory.length <= 0 && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Event Type<span className="text-danger">*</span></label>
                              {/* <select className="form-select form-control" aria-label="Default select example" multiple>
                                  {eventTypeList && eventTypeList.map((list) => (
                                      <option key={list.id} value={vendorData.vendor_event_type.find(
                                          (ele) => ele.event_type.id === list.id
                                      )}>{list.event_type}</option>
                                  ))}
                              </select> */}
                              <Select
                                  options={eventTypeList.map((data, index) => {
                                      return {
                                          label: data.event_type,
                                          value: data.id,
                                      };
                                  })}
                                  value={selectedEventType}
                                  onChange={handleChangeEventType}
                                  isMulti
                              />
                              {error && selectedEventType.length <= 0 && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Vendor Region's<span className="text-danger">*</span></label>
                                {/* <select className="form-select form-control" aria-label="Default select example" >
                                    {vendorData.vendor_regions && vendorData.vendor_regions.map((list) => (
                                        <option key={list.id} value={list.id}>{list.name}</option>
                                    ))}
                                </select> */}
                                <Select
                                    options={regionList.map((data, index) => {
                                        return {
                                            label: data.name,
                                            value: data.id,
                                        };
                                    })}
                                    value={selectedRegion}
                                    onChange={handleChangeRegion}
                                    isMulti
                                />
                                {error && selectedRegion.length <= 0 && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Starting Price</label>
                              <input type="number" className="form-control border-input" placeholder="Price" name="starting_price" 
                                onChange={handleChange}
                                value={vendorData.starting_price || ''}
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>State<span className="text-danger">*</span></label>
                              {/* <input type="text" className="form-control border-input" placeholder="state" name="state" 
                                onChange={handleChange}
                                value={vendorData.state || ''}
                              /> */}

                              <Select
                                  options={[
                                    { label: 'New South Wales', value: 'New South Wales' },
                                    { label: 'Queensland', value: 'Queensland' },
                                    { label: 'Northern Territory', value: 'Northern Territory' },
                                    { label: 'Western Australia', value: 'Western Australia' },
                                    { label: 'South Australia', value: 'South Australia' },
                                    { label: 'Victoria', value: 'Victoria' },
                                    { label: 'The Australian Capital Territory', value: 'The Australian Capital Territory' },
                                    { label: 'Tasmania', value: 'Tasmania' },
                                ]}
                                  value={selectedState}
                                  onChange={handleChangeState}
                                  name="state"
                              />
                              {error && !selectedState.value && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Postal Code<span className="text-danger">*</span></label>
                              <input type="number" className="form-control border-input" 
                                onChange={handleChange} name="postcode"
                                min="0"
                                placeholder="postal code" value={vendorData.postcode || ''}
                              />
                              {error && !vendorData.postcode && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group textarea-height">
                              <label>Description<span className="text-danger">*</span></label>
                              <textarea rows="5" className="form-control border-input" placeholder="Here can be a description"
                                maxLength="4000"
                                onChange={handleChange} 
                                name="vendor_description"
                                value={vendorData.vendor_description || ""}>
                              </textarea>
                              {error && !vendorData.vendor_description && (
                                  <span style={{ color: "red" }} className="invalidInput">
                                      Required*
                                  </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-content photos-section">
                        <div className="admin-photos">
                          <h4 className="common-label"> PHOTOS <span className="small"> {promoPhotoCount}/10</span></h4>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-2">
                              <div className="profile-cover-section">
                                  <div className="avatar-upload-sec profile-avtar-sec">
                                      <span className="upload-image-zone">
                                          <img src="/img/Download.svg" alt="" />
                                          <input
                                              type="file"
                                              accept=".jpeg,.png,.jpg"
                                              onChange={(e) => handleVendorPhotos(e)}
                                              multiple
                                              className="admin-photos-input"
                                          />
                                      </span>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-10">
                            <div className="vendor-event-carousel">
                              <OwlCarousel className="swiper-slide owl-carousel owl-theme owl-carousel-one owl-loaded owl-drag" margin={30} >
                                  {photos.map((photo, index) => {
                                      return (
                                          <div className="item" key={photo.id}>
                                              <span className="portfolio-slider">
                                                  <img src={photo} style={{ height: "100px" }} alt="vendorimage"/>
                                                  <i className="fa fa-close" onClick={() => deleteCurrentImage(index) }></i>
                                              </span>
                                          </div>
                                      );
                                  })}
                                  {vendor_photos?.map((photo, index) => {
                                      if (photo.type === "image") {
                                          return (
                                              <div className="item" key={photo.id}>
                                                  <span className="portfolio-slider">
                                                      <img src={photo.photos} style={{ height: "100px" }} alt="vendorimage"/>
                                                      <i className="fa fa-close" onClick={() => deleteImage(photo, index)}></i>
                                                  </span>
                                              </div>
                                          );
                                      }
                                  })}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-content video-details">
                        <h4 className="common-label"> PROMO VIDEO <span className="small"> {promoVideoCount} / 2</span></h4>
                        <hr />
                        <div className="row">
                          {vendor_photos.length > 0 &&
                            vendor_photos.map((photo, index) => {
                              if (photo.type === "video" && photo.photos) {
                                return (
                                  <div className="col-md-6">
                                    <div className="vendor-video" key={photo.id} >
                                      <div className="upload-video-sec upload-pro-sec video-section">
                                        <span>
                                          <video
                                              // className="video"
                                              id="video-preview"
                                              src={photo.photos}
                                              style={{ height: "100%", borderRadius: "15px", objectFit: "fill",}}
                                              alt="video"
                                              type="video/mp4"
                                              controls
                                              controlsList="nodownload noplaybackrate"
                                              disablePictureInPicture
                                          >
                                              {/* <source src={photo.photos} style={{ height: '100%', opacity: 0.7 }} alt="video" type="video/mp4" />
                                          <source src={photo.photos} style={{ height: '100%', opacity: 0.7 }} alt="video" type="video/quicktime" /> */}
                                          </video>
                                          <span style={{top:"35%"}} onClick={() => deleteVideo(photo, index) }>
                                              &times;
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                          {videos.map((video, index) => {
                            return (
                              <div className="col-md-6 ">
                                {video ? (
                                    <div className="upload-video-sec upload-pro-sec video-section">
                                        <span>
                                            <video
                                                // className="video"
                                                id="video-preview"
                                                controls
                                                controlsList="nodownload noplaybackrate"
                                                disablePictureInPicture
                                                src={video.url}
                                                style={{ height: "100%",borderRadius: "15px", objectFit: "fill"}}
                                                alt="video"
                                                type="video/mp4"
                                            >
                                            </video>
                                            <span style={{top:"35%"}} onClick={() => deleteCurrentVideo( video.url, index, video.id )}>
                                                &times;
                                            </span>
                                        </span>
                                    </div>
                                ) : (
                                    ""
                                )}
                              </div>
                            );
                          })}
                          {promoVideoCount < 2 && (
                            <div className="col-md-6">
                                <div className="upload-video-sec upload-pro-sec video_input">
                                  <div>
                                      {loadingVideoFile ? (
                                            <span>
                                                <img src="/img/ajax-loader.gif" alt="" />
                                                <span className="progress-bar">{videoProgress}</span>
                                            </span>
                                      ) : (
                                          <>
                                              <span>+</span>
                                              <input
                                                  type="file"
                                                  name="event_photos"
                                                  accept=".mp4,.mov"
                                                  id="video-file-input"
                                                  onChange={(e) => handleVendorVideos(e)}
                                              />
                                          </>
                                      )}
                                    </div>
                                </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-10">
                    <div className="card">
                      <div className="card-content video-details">
                        <div className="row" >
                          <div className="reels col-md-8 col-12">
                            <div className="video-Landscape">
                                <h5 style={{ marginTop: "20px" }}> Landscape Reel </h5>
                            </div>
                            {landscapeReel.length > 0 ? (
                                <div className="uploaded-video-sec reels-section">
                                    <span>
                                        <video className="video" controls controlsList="nodownload noplaybackrate" disablePictureInPicture >
                                            <source
                                                src={ landscapeReel[0].file ? landscapeReel[0].file : landscapeReel ? landscapeReel : ""}
                                                style={{ height: "100%", opacity: 0.7 }}
                                                alt="video"
                                                type="video/mp4"
                                            />
                                        </video>
                                        <span onClick={() => deleteCurrentReel("landscape",landscapeReel[0])}>
                                            <i className="fa fa-times  fa-1" aria-hidden="true"></i>
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className="uploaded-video-sec">
                                    {loadingLandReelFile ? (
                                        <div>
                                            <span>
                                                <img src="/img/ajax-loader.gif" alt="" />
                                                <span className="progress-bar">{landscapeReelProgress}</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            <span>+</span>
                                            <input
                                                type="file"
                                                name="vendor_reels"
                                                accept=".mp4,.mov"
                                                onChange={(e) => handleReels(e, "landscape")}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                          </div>
                          <div className="reels col-md-4 col-12">
                            <div className="video-Portrait">
                                <h5 style={{ marginTop: "20px" }}> Portrait Reel </h5>
                            </div>
                            {portraitReel.length > 0 ? (
                                <div className="uploaded-video-sec reels-section">
                                    <span>
                                        <video className="video" controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                            <source
                                                src={ portraitReel[0]?.file || portraitReel?.file }
                                                style={{ height: "100%", opacity: 0.7 }}
                                                alt="video"
                                                type="video/mp4"
                                            />
                                            <source
                                                src={ portraitReel[0]?.file || portraitReel?.file}
                                                style={{ height: "100%", opacity: 0.7 }}
                                                alt="video"
                                                type="video/quicktime"
                                            />
                                        </video>
                                        <span onClick={() => deleteCurrentReel("portrait",portraitReel[0])}>
                                            <i className="fa fa-times  fa-1" aria-hidden="true"></i>
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className="uploaded-video-sec">
                                    {loadingPotrateReelFile ? (
                                        <div>
                                            <span>
                                                <img src="/img/ajax-loader.gif" alt="" />
                                                <span className="progress-bar">{portraitReelProgress}</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            <span>+</span>
                                            <input
                                                type="file"
                                                name="vendor_reels"
                                                accept=".mp4,.mov"
                                                onChange={(e) =>
                                                    handleReels(e, "portrait")
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                          </div>
                          {error && !reels.length > 0 && (
                            <div className="col-md-10 col-12">
                              <span style={{ color: "red", left:"17px" }} className="invalidInput" >
                                  Reel Required*
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-content video-details">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="common-label" htmlFor="">
                                  Reel Description{" "}
                              </label>
                              <textarea
                                  row="4"
                                  maxLength="300"
                                  className="form-control"
                                  placeholder="..."
                                  name="reel_caption"
                                  value={vendorData.reel_caption || ""}
                                  onChange={handleChange}
                              >
                                  {" "}
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
                {newDetails.vendor_subscription && newDetails.vendor_subscription !== null ? (
                  <div className="text-start">
                    <button type="submit" className="btn btn-info btn-fill btn-wd" style={{ marginRight: '10px' }}>Save and publish</button>
                    <Link to={`/admin/vendor-analytics/${id}`} className="btn btn-info btn-fill btn-wd" style={{ marginRight: '10px' }}>View Analytics</Link>
                    <button type="button" className="btn btn-fill btn-wd" title="cancel" onClick={() => navigate(-1)}>Cancel</button>
                  </div>
                ) : (
                  <Link to={`/admin/vendor-analytics/${id}`} className="btn btn-info btn-fill btn-wd">View Analytics</Link>
                )}
              </form>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default VendorDetails;
