// import  axios  from "axios";
import {consumerData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const consumerFunction = () => async (dispatch) =>{
    try{
      const  consumerList  = await commonService.withLogin(`/consumer-list`)
      if(consumerList){

        dispatch({type: consumerData.GET_CONSUMER_SUCCESS , payload: {datasss: consumerList.data}})
      }
      else{
        dispatch({type: consumerData.GET_CONSUMER_FAILURE , payload: {data: consumerList.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: consumerData.GET_CONSUMER_FAILURE , payload: error.message})
  }
}

export default consumerFunction;