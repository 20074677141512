import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import { useDispatch, useSelector } from "react-redux";
import feedbackFunction from "../../actions/feedbackaction";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Loader from 'react-js-loader';
import $ from "jquery";
import moment from 'moment';

const FeedbackList = () => {
  const [newFeedback, setNewFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { feedback } = useSelector((state) => state.feedback);

  useEffect(() => {
    dispatch(feedbackFunction());
  }, [dispatch]);

  useEffect(() => {
    if (feedback && feedback.datasss && feedback.datasss.data) {
      setNewFeedback(feedback.datasss.data);
      setLoading(false)
    }
  }, [feedback]);

  return (
    newFeedback && newFeedback.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
                       {loading ? (
          <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
      </div>
            ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="title">Feedback</h4>
                  <br />
                  <div className="card">
                    <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables">
                      {newFeedback.length > 0 ? ( <table
                          id="datatables"
                          className="table table-striped table-no-bordered table-hover"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Full Name</th>
                              <th>Email</th>
                              <th>Feedback</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                                {newFeedback.map((user,i) => (
                                  <>
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{user.user.first_name + ' ' + user.user.last_name}</td>
                                      <td>{user.user.email}</td>
                                      <td>{user.message}</td>
                                      <td>{user?.created_at ? moment(user.created_at).format('YYYY-MM-DD') : '-'}</td>
                                      <td>
                                        {" "}
                                        <button className="btn btn-info">
                                          Reply
                                        </button>{" "}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                         
                          </tbody>
                        </table>
                        ): (
                        <p>No data found.</p>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
           )}
      </>
    )
  );
};

export default FeedbackList;



