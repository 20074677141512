// import  axios  from "axios";
import { dashboardCount } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const dashboardData = () => async (dispatch) =>{
    try{
      const  dashboardData  = await commonService.withLogin( `/dashboard-count`)
      if(dashboardData){
        dispatch({type: dashboardCount.GET_DASHBOARD_SUCCESS , payload: {datasss: dashboardData.data}})
      }
      else{
        dispatch({type: dashboardCount.GET_DASHBOARD_FAILURE , payload: {data: dashboardData.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: dashboardCount.GET_USER_FAILURE , payload: error.message})
  }
}

export default dashboardData;