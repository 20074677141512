import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import NavbarLog from "../../pages/login/NavbarLog";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Loader from 'react-js-loader';
import moment from 'moment';
import chatLogsAction from "../../../actions/chatLogsAction";
import { getDatabase, ref, onValue } from "firebase/database";
import axios from "axios";

const ChatLogs = () => {
  const [logs, setlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncinglog, setSyncinglog] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(chatLogsAction());
  }, [dispatch]);

  const { chatLogs } = useSelector((state) => state.chatLogs);
  useEffect(() => {
    if (chatLogs && chatLogs.datasss && chatLogs.datasss.data) {
        setlogs(chatLogs.datasss.data);
      setLoading(false)
    }
  }, [chatLogs]);
  const handleUserAction = (isBulk,selectedLog=[]) => {
    // confirm sync data action
    Swal.fire({
      title: "Are you sure you want to continue?",
      text: "Clicking 'Sync' will retrieve data from Firebase and increment the count of paid hits.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#008000",
      confirmButtonText: "Sync",
    }).then((result) => {
      if (result.isConfirmed) {
        const logDataToUpdate = isBulk?logs:[selectedLog];
        setIsSyncing(isBulk?true:false);
        setSyncinglog(selectedLog);
        syncChatLogsData(logDataToUpdate);
      }
    });
  };
  const syncChatLogsData = async (logData) => {
    const bulkData = [];
    if(logData.length > 0){
      const db = getDatabase();
      await logData.map((chatlog, index) => (
        onValue(
          ref(
              db,
              "messages/"+chatlog.event_id+"_"+chatlog.vendor_id+"_"+chatlog.user_id
          ),
          async (snapshot) => {
              const data = await snapshot.val();
              if (data) {
                  const allKeys = Object.keys(data).sort((a, b) => b.created_at - a.created_at);
                  const initiatedAt = data[allKeys[0]].created_at;
                  const [vendorCount, vendorLastActivity] = getSenderChatInsight(data,chatlog.vendor_id);
                  const [userCount, userLastActivity] = getSenderChatInsight(data,chatlog.user_id);
                  const newdata = {
                    event_id: chatlog.event_id,
                    vendor_id: chatlog.vendor_id,
                    user_id: chatlog.user_id,
                    vendor_count: vendorCount,
                    vendor_last_activity: vendorLastActivity,
                    user_count: userCount,
                    user_last_activity: userLastActivity,
                    initiated_at: initiatedAt
                  };
                  // Push the newdata into the bulkdata array
                  bulkData.push(newdata);
                  if(index === logData.length -1) {
                    /* update data */
                    updateChatLogs(bulkData);
                   }
              } 
          }
      )
      ))
    }
  };
  // Function to get count and latest sent timestamp for sender
  function getSenderChatInsight(data,senderId) {
    const senderData = Object.keys(data)
                              .filter(key => data[key]['sender_id']===senderId)
                              .reduce((obj, key) => {
                                  obj[key] = data[key];
                                  return obj;
                              }, {});
    const senderCount = Object.keys(senderData).length;
    const keys = Object.keys(senderData).sort((a, b) => b.created_at - a.created_at);
    const lastMessage = senderData[keys[keys.length - 1]];
    const lastMessageTimestamp = senderCount>0?lastMessage.created_at:0;
    return [senderCount, lastMessageTimestamp];
  }
  // bulk update chatlog count, last activity timestamp and chat initiated time from firestore
  const updateChatLogs = async (chatLogData) => {
    //console.log("before API call",chatLogData);
      let tokenObj = JSON.parse(localStorage.getItem("token"));
      const headers = {
          "content-type": "application/json",
          Authorization: `Bearer ${tokenObj.api_token}`,
      };
      await axios.put(
          `${process.env.REACT_APP_BASE_URL}/bulk-update-event-chats-activity`,
          chatLogData,
          { headers }
      ).then((response) => {
        dispatch(chatLogsAction());
        setIsSyncing(false);
        setSyncinglog([]);
      }).catch(err => {
          console.log('error in updating chat logs', err);
      });
  };

  return (
    logs && logs.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
      {loading ? (
        <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
        </div>
      ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <ToastContainer />
                  <div className="card">
                    <div className="header">
                    <div className="row">
                      <div className="col-md-6">
                        <div style={{ float: 'left'}}>
                        <h4 className="title">Chat Logs</h4>
                        <p className="category">Here are the list of all the chats nodes in the market place.</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div style={{ float: 'right'}}>
                        <Link className={`btn btn-warning ${isSyncing ? 'disabled' : ''}`} href="#" onClick={() => handleUserAction(true)}>
                        <i className={`fa fa-refresh fa-lg ${isSyncing ? 'fa-spin' : ''}`} aria-hidden="true"></i>&nbsp;&nbsp;Sync All Data</Link>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="card-content">
                        <div className="toolbar"></div>
                        <div className="fresh-datatables content table-responsive table-full-width">
                        {logs.length > 0 ? (
                            <table id="datatables" className="table table-striped table-no-bordered table-hover" width="100%">
                                <thead className="text-primary">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Sender Name</th>
                                        <th>Sender Message Count</th>
                                        <th>Sender Last Activity</th>
                                        <th>Event Name</th>
                                        <th>Receiver Name</th>
                                        <th>Receiver Message Count</th>
                                        <th>Receiver Last Activity</th>
                                        <th>Chat Initiated At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            {(data?.event_dtl) ?
                                                <>
                                                    <td><Link to={`/admin/vendor-details/${data?.vendor_dtl?.id}`}>{(data?.vendor_dtl?.first_name) ? data?.vendor_dtl?.first_name : ''}{" "}{(data?.vendor_dtl?.last_name) ? data?.vendor_dtl?.last_name : '-'}</Link> <strong title="Vendor" className="text-info">( V )</strong></td>
                                                    <td>{(data?.vendor_count) ? data?.vendor_count : 0}</td>
                                                    <td>{(data.vendor_last_activity) ? moment(data?.vendor_last_activity).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                    <td>{(data?.event_dtl?.event_name) ? data?.event_dtl?.event_name : '-'}</td>
                                                    <td><Link to={`/admin/consumer-details/${data?.consumer_dtl?.id}`}>{(data?.consumer_dtl?.first_name) ? data?.consumer_dtl?.first_name : ''}{" "}{(data?.consumer_dtl?.last_name) ? data?.consumer_dtl?.last_name : '-'}</Link></td>
                                                    <td>{(data?.user_count) ? data?.user_count : 0}</td>
                                                    <td>{(data.user_last_activity) ? moment(data?.user_last_activity).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                </>
                                            :
                                                <>
                                                    <td><Link to={`/admin/consumer-details/${data?.consumer_dtl?.id}`}>{(data?.consumer_dtl?.first_name) ? data?.consumer_dtl?.first_name : ''}{" "}{(data?.consumer_dtl?.last_name) ? data?.consumer_dtl?.last_name : '-'}</Link> <strong title="Consumer" className="text-info">( C )</strong></td>
                                                    <td>{(data?.user_count) ? data?.user_count : 0}</td>
                                                    <td>{(data.user_last_activity) ? moment(data?.user_last_activity).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                    <td>{(data?.event_dtl?.event_name) ? data?.event_dtl?.event_name : '-'}</td>
                                                    <td><Link to={`/admin/vendor-details/${data?.vendor_dtl?.id}`}>{(data?.vendor_dtl?.first_name) ? data?.vendor_dtl?.first_name : ''}{" "}{(data?.vendor_dtl?.last_name) ? data?.vendor_dtl?.last_name : '-'}</Link></td>
                                                    <td>{(data?.vendor_count) ? data?.vendor_count : 0}</td>
                                                    <td>{(data.vendor_last_activity) ? moment(data?.vendor_last_activity).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                </>
                                            }
                                            <td>{(data.created_at) ? moment(data?.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                            <td>
                                              <Link data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sync Data" to="#" className={`btn btn-warning ${syncinglog.id===data.id ? 'disabled' : ''}`} onClick={() => handleUserAction(false, data)}>
                                                <i className={`fa fa-refresh ${syncinglog.id===data.id ? 'fa-spin' : ''}`} aria-hidden="true"></i>
                                              </Link>{" "}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                No chat not created yet!
                            </div>
                        )}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
      </>
    )
  );
};

export default ChatLogs;
