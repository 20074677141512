import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Loader from 'react-js-loader';
import moment from 'moment';
import eventListAction from "../../actions/eventAtcion";
import eventBlockAction from "../../actions/eventBlockAction";



const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(eventListAction());
  }, [dispatch]);

  const handleEventBlock = (eventId, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: status === "0" ? "You want unblock this event" : "You want block this event",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: status === "0" ? "unblock!" : "block!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('result',result);
        dispatch(eventBlockAction({
            eventId: eventId,
            temp_down: status,
        }));
        var msg = status === "0" ? "event unblocked successfully!" : "event blocked successfully!";
        toast.success(msg);
        // dispatch(eventListAction());
      }
    });
};

  const { eventList } = useSelector((state) => state.eventList);
  useEffect(() => {
    if (eventList && eventList.datasss && eventList.datasss.data) {
      setEvents(eventList.datasss.data);
      setLoading(false)
    }
  }, [eventList]);

  const { eventBlock } = useSelector((state) => state.eventBlock);
  useEffect(() => {
    if (eventBlock && eventBlock.datasss && eventBlock.datasss.data) {
      dispatch(eventListAction());
      setLoading(false)
    }
  }, [dispatch,eventBlock]);

  return (
    events && events.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
      {loading ? (
        <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
        </div>
      ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <ToastContainer />
                  <div className="card">
                    <div className="header">
                      <h4 className="title">Event List</h4>
                      <p className="category">Here is the list of all the events in the Marketplace.</p>
                    </div>
                    <div className="card-content">
                        <div className="toolbar"></div>
                        <div className="fresh-datatables content table-responsive table-full-width">
                            <table id="datatables" className="table table-striped table-no-bordered table-hover" cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap text-info">S.No</th>
                                        <th className="text-nowrap text-info">Event Host</th>
                                        <th className="text-nowrap text-info">Event Name</th>
                                        <th className="text-nowrap text-info">Event Type</th>
                                        <th className="text-nowrap text-info">Budget</th>
                                        <th className="text-nowrap text-info">Vendor Required</th>
                                        <th className="text-nowrap text-info" style={{width:"100px", whiteSpace : "break-spaces"}}>Vendor Responses</th>
                                        <th className="text-nowrap text-info">Start Date</th>
                                        <th className="text-nowrap text-info">Status</th>
                                        <th className="text-nowrap text-info">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {events && events.length > 0 ? (
                                        <>
                                            {events.map((event,index) => (
                                                <tr key={index}>
                                                    <td className="text-nowrap">{index+1}</td>
                                                    <td className="text-nowrap"><Link to={`/admin/consumer-details/${event?.user?.id}`}>{(event?.user?.first_name) ? event?.user?.first_name : ''}{" "}{(event?.user?.last_name) ? event?.user?.last_name : '-'}</Link></td>
                                                    <td className="text-nowrap"><Link to={`/admin/event-detail/${event?.id}`}>{event?.event_name}</Link></td>
                                                    <td className="text-nowrap">{event?.event_type}</td>
                                                    <td className="text-nowrap">{event?.event_budget ? `$`+(event?.event_budget) : '-'}</td>
                                                    <td>
                                                      <div className="height-fix">
                                                        {event?.event_category?.map((category, index) => {
                                                          {console.log('category',category)}
                                                          return (
                                                            <li key={index}>{category?.category?.category_name}</li>
                                                          );
                                                        })}
                                                      </div>
                                                    </td>
                                                    <td className="text-center">{event?.vendor_response ? (event?.vendor_response) : '0'}</td>
                                                    <td className="text-nowrap">{event?.event_from_date ? moment(event.event_from_date).format('YYYY-MM-DD') : '-'}</td>
                                                    <td>{event?.event_to_date && new Date(event.event_to_date) < new Date() ?
                                                        (<h4><strong className="text-danger">Expired</strong></h4>)
                                                        :
                                                        (<h4><strong className="text-success">Active</strong></h4>)
                                                    }</td>
                                                    <td>
                                                        <Link data-bs-toggle="tooltip" data-bs-placement="bottom" title="Event Block" className={event.temp_down === "0" ? "btn btn-danger" : "btn btn-info"}
                                                            onClick={() => handleEventBlock(event?.id, event?.temp_down === "0" ? "1" : "0") }
                                                        >
                                                            <i className="fa fa-ban" aria-hidden="true" ></i>
                                                        </Link>{" "}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ):(
                                        <tr><td className="text-center" colSpan="8">No event found.</td></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
      </>
    )
  );
};

export default EventList;
