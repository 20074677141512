import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConsumerAnalyticsAction from "../../actions/consumerAnalyticsAction";
import userLoginLogsAction from "../../actions/userLoginLogsAction";
import { useParams } from "react-router";
import NavbarLog from "../pages/login/NavbarLog";
import Footer from "../Footer";
import { toast } from "react-toastify";
import moment from "moment";
import $ from "jquery";
import Loader from 'react-js-loader';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import addDays from "date-fns/addDays";


const ConsumerAnalytics = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const allowedLogHistory = 90;

    const [loginLogs, setLoginLogs] = useState([]);
    const [favList, setFavList] = useState([]);
    const [messageThread, setMessageThread] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dateInterval, setDateInterval] = useState([]);
    const [isCustomRange, setIsCustomRange] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        setDateInterval([
            {
            start: startDate,
            end: addDays(startDate, allowedLogHistory)
            },
        ]);
    }, [startDate]);

    const fetchLoginLogsData = (isCustom) => {
        setLoading(true);
        setIsCustomRange(true);
        if(isCustom===false)
        {
            setDateRange([null,null]);    
        }
        dispatch(userLoginLogsAction({
            id: id,
            startDate: isCustom&&startDate?moment(startDate).format('YYYY-MM-DD'):"",
            endDate: isCustom&&endDate?moment(endDate).format('YYYY-MM-DD'):"",
        }));
    };
    
    useEffect(() => {
        setLoading(true);
        dispatch(ConsumerAnalyticsAction(id));
    }, [dispatch, id]);

    // Function to format total time from minutes to hours and minutes
    const formatTotalTime = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        if (hours > 0 && minutes > 0) {
            return `${hours} hrs ${minutes} min`;
        } else if (hours > 0) {
            return `${hours} hrs`;
        } else {
            return `${minutes} min`;
        }
    };

    // Selects data related to the user Login logs action for custom range from the Redux store state.
    const { userLogs } = useSelector((state) => state.userLoginLogs.userLogs);
    useEffect(() => {
        if(isCustomRange){
        if (userLogs && userLogs.status) {
            setLoginLogs(userLogs?.data?.login_logs);
            setLoading(false);
        }else if (userLogs && userLogs.status === false) {
            setLoading(false);
            toast.error(userLogs.message);
        }
    }
    }, [userLogs, isCustomRange]);

    // Selects data related to the consumerAnalytics action from the Redux store state.
    const { analytics } = useSelector((state) => state.consumerAnalytics.consumerAnalytics);
    useEffect(() => {
        if (analytics && analytics.status) {
            setLoginLogs(analytics?.data?.login_logs);
            setFavList(analytics?.data?.favourite_list);
            setMessageThread(analytics?.data?.message_thread)
            setLoading(false);
        }else if (analytics && analytics.status === false) {
            setLoading(false);
            toast.error(analytics.message);
        }
    }, [analytics, dispatch]);

    useEffect(() => {
        if (favList && favList.length > 0) {
            $(document).ready(function () {
                $("#favListDatatable").DataTable({
                    "responsive": true,
                    "bDestroy": true,
                    "pageLength": 5,
                    "lengthMenu": [5, 10, 25, 50, 100],
                    "zeroRecords": "No matching records found", // Text when no records match the search criteria
                    "emptyTable": "No data available in table" // Text when the table is empty
                });
            })
        }
    },[favList])

    useEffect(() => {
        if (messageThread && messageThread.length > 0) {
            $(document).ready(function () {
                $("#messageThreadDatatable").DataTable({
                    "responsive": true,
                    "bDestroy": true,
                    "pageLength": 5,
                    "lengthMenu": [5, 10, 25, 50, 100],
                    "zeroRecords": "No matching records found", // Text when no records match the search criteria
                    "emptyTable": "No data available in table" // Text when the table is empty
                });
            })
        }
    },[messageThread])

    useEffect(() => {
        if (loginLogs && loginLogs.length > 0) {
            $(document).ready(function () {
                $("#loginLogsDatatable").DataTable({
                    "responsive": true,
                    "bDestroy": true,
                    "pageLength": 5,
                    "lengthMenu": [5, 10, 25, 50, 100],
                    "zeroRecords": "No matching records found", // Text when no records match the search criteria
                    "emptyTable": "No data available in table" // Text when the table is empty
                });
            })
        }
    },[loginLogs])

    return (
    <>
        {loading ? (
            <div className={"item loader-item"}>
                <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
            </div>
        ) : (
            <div className="main-panel">
                <NavbarLog />
                <div className="content">
                    <div className="container-fluid">
                        <div className="card-header">
                            <Link to={`/admin/consumer-details/${id}`} className="btn btn-info btn-fill btn-wd" style={{ marginBottom: '10px' }}>View Profile</Link>
                        </div>
                        <div className="row">
                            {/*Login Logs section */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div style={{ justifyContent: "space-between", display : 'flex'}}>
                                            <h4 className="card-title">Login logs</h4>
                                            <h4 className="card-title"><strong>Total Time </strong>{formatTotalTime(loginLogs.reduce((total, time) => total + (time.total_time || 0), 0))}</h4>
                                        </div>
                                        <p className="card-category">Here are the sign-in log details for latest logs upto 10 Records. For History, you can select custom date range (90 Days) from below.</p>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            {/*Login Logs date range filter section Start */}
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div>Select DateRange:</div>
                                                        <DatePicker
                                                        showIcon
                                                        toggleCalendarOnIconClick
                                                        icon="fa fa-calendar"
                                                        className="form-control"
                                                        selectsRange={true}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(update) => {
                                                            setDateRange(update);
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        includeDateIntervals={startDate ? dateInterval : null}
                                                        isClearable={true}
                                                        maxDate={new Date()}
                                                        placeholderText="Start Date - End Date"
                                                        />
                                                        </div>
                                                <div className="col-md-4">
                                                    <div>&nbsp;</div>
                                                    <Button color="primary" onClick={() => fetchLoginLogsData(true)} disabled={startDate==null||endDate==null?true:false}>Search Logs</Button>
                                                    {' '}
                                                    <Button color="primary" onClick={() => fetchLoginLogsData(false)} disabled={!isCustomRange?true:false}>Reset</Button>
                                                </div>
                                            </div>
                                            {/*Login Logs date range filter section End */}
                                            <div className="fresh-datatables content table-responsive table-full-width">
                                            {loginLogs.length > 0 ? (
                                                <table id="loginLogsDatatable" className="table table-striped table-no-bordered table-hover" width="100%">
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Device</th>
                                                            <th>Login Time</th>
                                                            <th>Time Spent</th>
                                                            <th>Logout</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loginLogs.map((logs, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{(logs.device_type) ? (logs.device_type.charAt(0).toUpperCase() + logs.device_type.slice(1))  : ''}</td>
                                                                    <td>{(logs.login_time) ? moment(logs?.login_time).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                                    <td>{(logs?.total_time) ? formatTotalTime(logs?.total_time) : 'Less than 1 min'}</td>
                                                                    <td>{(logs?.logout_time) ? moment(logs?.logout_time).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div style={{ textAlign: 'center', padding: '10px' }}>
                                                    No logs found!
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/*Favourite section */}
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div style={{ justifyContent: "space-between", display : 'flex'}}>
                                            <h4 className="card-title">Favourite list</h4>
                                            <h4 className="card-title"><strong>Total{" "}{(favList.length > 0) ? favList.length : '0'}</strong></h4>
                                        </div>
                                        <p className="card-category">These consumers has added this vendor to their favorites list</p>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="fresh-datatables content table-responsive table-full-width">
                                            {favList.length > 0 ? (
                                                <table id="favListDatatable" className="table table-striped table-no-bordered table-hover" width="100%">
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Vendor</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {favList.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <Link to={`/admin/vendor-details/${data?.vendor?.id}`}>
                                                                        {(data?.vendor?.first_name) ? data?.vendor?.first_name : ''}{" "}{(data?.vendor?.last_name) ? data?.vendor?.last_name : ''}
                                                                    </Link>
                                                                </td>
                                                                <td>{(data.created_at) ? moment(data?.created_at).format('YYYY-MM-DD') : '-'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div style={{ textAlign: 'center', padding: '10px' }}>
                                                    No favourites list found!
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Message Thread section */}
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div style={{ justifyContent: "space-between", display : 'flex'}}>
                                            <h4 className="card-title">Consumer Chat Node</h4>
                                            <h4 className="card-title"><strong>Total{" "}{(messageThread.length > 0) ? messageThread.length : '0'}</strong></h4>
                                        </div>
                                        <p className="card-category">Event chat node inciated by consumer for consumer</p>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="fresh-datatables content table-responsive table-full-width">
                                            {messageThread.length > 0 ? (
                                                <table id="messageThreadDatatable" className="table table-striped table-no-bordered table-hover" width="100%">
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Sender</th>
                                                            <th>On Event</th>
                                                            <th>Receiver</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {messageThread.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                {(data?.event_dtl) ?
                                                                    <>
                                                                        <td><Link to={`/admin/vendor-details/${data?.vendor_dtl?.id}`}>{(data?.vendor_dtl?.first_name) ? data?.vendor_dtl?.first_name : ''}{" "}{(data?.vendor_dtl?.last_name) ? data?.vendor_dtl?.last_name : '-'}</Link><strong title="Vendor" className="text-info">( V )</strong></td>
                                                                        <td>{(data?.event_dtl?.event_name) ? data?.event_dtl?.event_name : '-'}</td>
                                                                        <td><Link to={`/admin/consumer-details/${data?.consumer_dtl?.id}`}>{(data?.consumer_dtl?.first_name) ? data?.consumer_dtl?.first_name : ''}{" "}{(data?.consumer_dtl?.last_name) ? data?.consumer_dtl?.last_name : '-'}</Link></td>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <td><Link to={`/admin/consumer-details/${data?.consumer_dtl?.id}`}>{(data?.consumer_dtl?.first_name) ? data?.consumer_dtl?.first_name : ''}{" "}{(data?.consumer_dtl?.last_name) ? data?.consumer_dtl?.last_name : '-'}</Link><strong title="Vendor" className="text-info">( C )</strong></td>
                                                                        <td>{(data?.event_dtl?.event_name) ? data?.event_dtl?.event_name : '-'}</td>
                                                                        <td><Link to={`/admin/vendor-details/${data?.vendor_dtl?.id}`}>{(data?.vendor_dtl?.first_name) ? data?.vendor_dtl?.first_name : ''}{" "}{(data?.vendor_dtl?.last_name) ? data?.vendor_dtl?.last_name : '-'}</Link></td>
                                                                    </>
                                                                }
                                                                <td>{(data.created_at) ? moment(data?.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div style={{ textAlign: 'center', padding: '10px' }}>
                                                    No chat not created yet!
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )}
    </>
  );
};

export default ConsumerAnalytics;
