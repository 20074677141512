import { postInactiveData } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const postInactiveFunction = (userid, post_id, report_type, status) => async (dispatch) => {
  try {
    // const headers = {
    //   'Authorization': "Bearer IeyBbzlD4Hi07aWZG976ZUSB0yjXWbKPDxta36Fm",
    //   'Content-Type': 'application/json'
    // }
    const userPostList = await commonService.withToken(`/post-inactive`, {
      id: userid,
      post_id: post_id,
      report_type_id: report_type,
      status: status,
    });
    if (userPostList) {
      dispatch({
        type: postInactiveData.GET_POSTINACTIVE_SUCCESS,
        payload: { datasss: userPostList.data },
      });
    } else {
      dispatch({
        type: postInactiveData.GET_POSTINACTIVE_FAILURE,
        payload: { data: userPostList.data },
      });
    }
  } catch (error) {
    console.log(`Error while calling login api ${error}`);
    dispatch({
      type: postInactiveData.GET_POSTINACTIVE_FAILURE,
      payload: error.message,
    });
  }
};

export default postInactiveFunction;
