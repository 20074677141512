import { RegionConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const regionAction = () => async (dispatch) => {
    try {
        const regionList = await commonService.withOutLogin(`/region-list`);
        if (regionList) {
            dispatch({ type: RegionConst.GET_REGION_SUCCESS, payload: { datasss: regionList.data } });
        }
        else {
            dispatch({ type: RegionConst.GET_REGION_FAILURE, payload: { data: regionList.data } });
        }
    }
    catch (error) {
        dispatch({ type: RegionConst.GET_REGION_FAILURE, payload: error.message })
    }
}

export default regionAction;