import { eventBlockConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

const eventBlockAction = (payload) => async (dispatch) => {
  try {
    const eventBlockResponse = await commonService.withToken(`/pause-event`, payload);
    if (eventBlockResponse) {
      dispatch({ type: eventBlockConst.GET_EVENTBLOCK_SUCCESS, payload: { datasss: eventBlockResponse.data },});
    } else {
      dispatch({ type: eventBlockConst.GET_EVENTBLOCK_FAILURE, payload: { data: eventBlockResponse },});
    }
  } catch (error) {
    console.log(`Error while calling login api ${error}`);
    dispatch({ type: eventBlockConst.GET_EVENTBLOCK_FAILURE, payload: error.message,});
  }
};

export default eventBlockAction;
