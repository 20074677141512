import { commonService } from "../services/common.service";
import { ConsumerAnalyticsConst } from "../constants/user.constants";

 const ConsumerAnalyticsAction = (id) => async (dispatch) =>{
    try{
      const  ConsumerAnalytics  = await commonService.withLogin(`/consumer-analytics/${id}`)
      if(ConsumerAnalytics){
        dispatch({type: ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_SUCCESS , payload: {analytics : ConsumerAnalytics.data}})
      }
      else{
        dispatch({type: ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_FAILURE , payload: {data: ConsumerAnalytics.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: ConsumerAnalyticsConst.GET_CONSUMERANALYTICS_FAILURE , payload: error.message})
  }
};

export default ConsumerAnalyticsAction;