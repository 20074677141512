import React, { useState, useEffect } from "react";
import NavbarLog from "../pages/login/NavbarLog";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import axios from "axios";
import moment from 'moment';
import Loader from 'react-js-loader';
import { Button } from "reactstrap";
import { Editor } from '@tinymce/tinymce-react'


const Setting = () => {
  const [settingPageList, setSettingPageList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show,setShow] = useState(false)
  const [pageData,setPageData] = useState({})

  // const { review } = useSelector((state) => state.review);

  useEffect(() => {
    // let tokenObj = JSON.parse(localStorage.getItem("token"));
    // const headers = {
    //     'content-type': 'application/json',
    //     Authorization: `Bearer ${tokenObj.api_token}`
    // }
  
    axios.get(`${process.env.REACT_APP_BASE_URL}/static-page-list`).then(response => {
      if(response && response.data){
      setSettingPageList(response.data.data)
      setLoading(false)
      }
    })
  }, []);
  
  const handleEdit = (e,data) => {
  let obj = {
    id:data.id,
    type:data.type,
    content: data.content
  }
  setPageData(obj)
   setShow(!show)   
}

const handleChange = (e) =>{
  // let pageContent = {...pageData}
  pageData.content = e

  setPageData(pageData)
}
const handleSubmit = () => {
  let tokenObj = JSON.parse(localStorage.getItem("token"));
    const headers = {
        'content-type': 'application/json',
        Authorization: `Bearer ${tokenObj.api_token}`
    }
  
    axios.post(`${process.env.REACT_APP_BASE_URL}/edit-content`,pageData, { headers }).then(response => {
      if(response && response.data){
     setShow(!show)
    axios.get(`${process.env.REACT_APP_BASE_URL}/static-page-list`).then(response => {
      if(response && response.data){
      setSettingPageList(response.data.data)
      setLoading(false)
      }
    })
      }
    })
}

  return (
    settingPageList && settingPageList.length > 0
      ? $(document).ready(function () {
          $("#datatables").DataTable();
        })
      : null,
    (
      <>
             {loading ? (
          <div className={"item loader-item"}>
          <Loader type="spinner-circle" bgColor={"#66615B"} color={'#66615B'} size={100} />
      </div>
            ) : (
        <div className="main-panel">
          <NavbarLog />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="title">Setting</h4>
                  <br />
                  <div className="card">
                   {!show && <div className="card-content">
                      <div className="toolbar"></div>
                      <div className="fresh-datatables content table-responsive table-full-width">
                      {settingPageList.length > 0 ? ( <table
                          id="datatables"
                          className="table table-striped table-no-bordered table-hover"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Page Name</th>
                              <th className="heading-content">Content</th>
                              <th>Last Update Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                                {settingPageList.map((data,i) => (
                                  <>
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{data.type}</td>
                                      <td className="description-content">{data.content}</td>
                                      <td>{data?.updated_at ? moment(data.updated_at).format('YYYY-MM-DD') : '-'}</td>
                                      <td>
                                        {" "}
                                        <div className="toggle-btn">
                                           <Button data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title="User Unblock"
                                                  className="btn btn-info report-btn-active"
                                                  onClick={(e)=>handleEdit(e,data)}
                                                  >
                                            Edit
                                           </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                             
                          </tbody>
                        </table>
                        ):(
                        <p>No data found.</p>
                      )}
                      </div>
                    </div>}
                   {show && <> <Editor
                    apiKey='myl0e0o061toxxstxlhf3z8k99yass5l8kzv26m174fjpfd1'
                    name='description'
                    initialValue={pageData.content}
                    onEditorChange = {handleChange}
                    init={{
                      selector: 'textarea',
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "table contextmenu paste"
                      ],
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat'
                    }}
                  />
                  <Button  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title="User Unblock"
                                                  className="btn btn-info report-btn-active" onClick={handleSubmit}>Submit</Button>
                  </>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )}
      </>
    )
  );
}

export default Setting;
