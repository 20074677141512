// import  axios  from "axios";
import { eventDetailConst } from "../constants/user.constants";
import { commonService } from "../services/common.service";

 const eventDetailAction = (id) => async (dispatch) =>{
    try{
      const  eventDetail  = await commonService.withLogin(`/view-event/${id}`)
      if(eventDetail){
        dispatch({type: eventDetailConst.GET_EVENTDETAIL_SUCCESS , payload: {datasss: eventDetail.data}})
      }
      else{
        dispatch({type: eventDetailConst.GET_EVENTDETAIL_FAILURE , payload: {data: eventDetail.data}})
      }
  }catch(error){
      console.log(`Error while calling login api ${error}`)
      dispatch({ type: eventDetailConst.GET_EVENTDETAIL_FAILURE , payload: error.message})
  }
}

export default eventDetailAction;